import React from 'react';
import H3 from './_common/H3';
import styled from 'styled-components';
import 'moment/locale/sv';
import 'moment/locale/en-gb';

const MonthPicker = ({ changeMonth, currMonth, lang }) => (
  <MonthContainer>
    <Link onClick={() => changeMonth('subtract')}>
      <Arrow type="left" />
    </Link>
    <MonthName>
      {lang === 'en'
        ? currMonth
            .clone()
            .locale('en-gb')
            .format('MMMM YYYY')
        : currMonth
            .clone()
            .locale('sv')
            .format('MMMM YYYY')}
    </MonthName>
    <Link onClick={() => changeMonth('add')}>
      <Arrow type="right" />
    </Link>
  </MonthContainer>
);

export default MonthPicker;

const Arrow = styled.i`
  border: solid black;
  box-sizing: border-box;
  border-width: 0 2px 2px 0;
  display: block;
  padding: 7px;
  transform: rotate(${(props) => (props.type === 'left' ? 135 : -45)}deg);
`;

const MonthContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled.a`
  cursor: pointer;
`;

const MonthName = styled(H3)`
  text-transform: capitalize;
`;
