import React from 'react';
import Button from './_common/Button';
import styled from 'styled-components';
import P from './_common/P';

const ButtonRow = ({
  back,
  next,
  funcNext,
  funcBack,
  submit,
  error,
  lang,
  unavailable,
  backUnavailable,
  appWidth,
  animating = false,
}) => (
  <ButtonsWrapper>
    <InnerWrapper>
      <Button
        slug="back"
        title={lang === 'en' ? 'back' : 'Tillbaka'}
        func={funcBack}
        page={back}
        unavailable={backUnavailable}
      />
      {error && appWidth >= 740 && <Error>{error}</Error>}
      <Button
        slug="next"
        title={lang === 'en' ? 'next' : 'Nästa'}
        func={funcNext}
        page={next}
        submit={!unavailable && submit}
        unavailable={unavailable}
        animating={animating}
      />
    </InnerWrapper>
    {error && appWidth <= 740 && <Error>{error}</Error>}
  </ButtonsWrapper>
);

export default ButtonRow;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const Error = styled(P)`
  color: #9e2146;
  text-align: center;
  margin-top: ${(props) => props.theme.width < 740 && '20px'} !important;
`;
