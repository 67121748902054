import React from 'react';
import styled from 'styled-components';
import P from './P';

const Input = React.forwardRef(
  ({ title, name, error, onChange, value, autocomplete, fontSize='20px' }, ref) => {
    return (
      <StyledDiv>
        <StyledInput
          placeholder={title}
          name={name}
          ref={ref}
          onChange={onChange}
          value={value}
          fontSize={fontSize}
          autoComplete={autocomplete && autocomplete}
        />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </StyledDiv>
    );
  },
);

export default Input;

const StyledDiv = styled.div`
  line-height: 24px;
`;

const StyledInput = styled.input`
  font-family: ${(props) => props.theme.mainFont} !important;
  font-size: ${(props) => props.theme.width < 740 ? '15px' : props.fontSize} !important;
  color: ${(props) => props.theme.black} !important;
  background-color: ${(props) => props.theme.grey};
  border: none !important;
  outline: none !important;
  height: 50px;
  width: 100% !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  border-bottom: 1px solid ${(props) => props.theme.black} !important;
  box-sizing: border-box;
  line-height: ${(props) => props.fontSize};
  &::placeholder {
    color: '#767676' !important;
    opacity: 1;
  }
`;

const ErrorMessage = styled(P)`
  position: absolute !important;
  color: red !important;
  font-size: 12px !important;
`;
