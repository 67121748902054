import React from 'react';
import ReactDOM from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/datepicker.css';
import Venue from './Venue';
import Summary from './Summary';
import Confirmation from './Confirmation';
import Payment from './Payment';
import Schedule from './Schedule';
import CalendarBlock from '../components/CalendarBlock';

const Calendar = (props) => {
  const {
    twoDaysSchedule,
    twoDaysPage,
    selectedDate,
    dayPart,
    setGuestNumber,
    currPage,
    guestsNumber,
    setUserInfo,
    book,
    userInfo,
    appWidth,
    title,
    addons,
    changeAddons,
    error,
    changePage,
    saveDetails,
    setDetails,
    selectDinnerOption,
    packages,
    changeAllergies,
    allergies,
    schedule,
    total,
    roomPrice,
    addonsPrices,
    changeSchedule,
    closeApp,
    lang,
    guidedTourTime,
    setTwoDaysPage,
    isBlocked,
    isPaying,
    isPaid,
    setIsPaying,
    maxGuests,
  } = props;

  return (
    <>
      <CalendarBlock {...props} />
      {currPage === 'venue' &&
        ReactDOM.createPortal(
          <Venue
            roomName={title}
            roomPrice={roomPrice}
            total={total}
            allergies={allergies}
            changeAllergies={changeAllergies}
            packages={packages}
            selectDinnerOption={selectDinnerOption}
            setCurrPage={changePage}
            closeApp={closeApp}
            selectedDate={selectedDate}
            selectedGuests={guestsNumber}
            setGuests={setGuestNumber}
            dayPart={dayPart}
            addons={addons}
            changeAddons={changeAddons}
            addonsPrices={addonsPrices}
            appWidth={appWidth}
            lang={lang}
            currPage={currPage}
            maxGuests={maxGuests}
          />,
          document.querySelector('body'),
        )}
      {currPage === 'schedule' &&
        ReactDOM.createPortal(
          <Schedule
            changeSchedule={changeSchedule}
            schedule={schedule}
            setCurrPage={changePage}
            selectedDate={selectedDate}
            twoDaysPage={twoDaysPage}
            twoDaysSchedule={twoDaysSchedule}
            addons={addons}
            appWidth={appWidth}
            closeApp={closeApp}
            lang={lang}
            guidedTourTime={guidedTourTime}
            addonsPrices={addonsPrices}
          />,
          document.querySelector('body'),
        )}
      {currPage === 'summary' &&
        ReactDOM.createPortal(
          <Summary
            roomName={title}
            guests={guestsNumber}
            allergies={allergies}
            addons={addons}
            packages={packages}
            setCurrPage={changePage}
            selectedDate={selectedDate}
            selectedGuests={guestsNumber}
            dayPart={dayPart}
            total={total}
            roomPrice={roomPrice}
            twoDaysSchedule={twoDaysSchedule}
            schedule={schedule}
            appWidth={appWidth}
            closeApp={closeApp}
            lang={lang}
            addonsPrices={addonsPrices}
            guidedTourTime={guidedTourTime}
            setTwoDaysPage={setTwoDaysPage}
          />,
          document.querySelector('body'),
        )}
      {currPage === 'payment' &&
        ReactDOM.createPortal(
          <Payment
            setCurrPage={changePage}
            setUserInfo={setUserInfo}
            book={book}
            userInfo={userInfo}
            error={error}
            details={saveDetails}
            setDetails={setDetails}
            appWidth={appWidth}
            closeApp={closeApp}
            total={total}
            lang={lang}
            isBlocked={isBlocked}
            isPaying={isPaying}
            isPaid={isPaid}
            setIsPaying={setIsPaying}
          />,
          document.querySelector('body'),
        )}
      {currPage === 'confirmation' &&
        ReactDOM.createPortal(
          <Confirmation
            setCurrPage={changePage}
            date={selectedDate}
            userInfo={userInfo}
            roomName={title}
            guests={guestsNumber}
            allergies={allergies}
            addons={addons}
            packages={packages}
            selectedDate={selectedDate}
            selectedGuests={guestsNumber}
            dayPart={dayPart}
            total={total}
            roomPrice={roomPrice}
            twoDaysSchedule={twoDaysSchedule}
            schedule={schedule}
            appWidth={appWidth}
            closeApp={closeApp}
            lang={lang}
            addonsPrices={addonsPrices}
            guidedTourTime={guidedTourTime}
            currPage={currPage}
          />,
          document.querySelector('body'),
        )}
    </>
  );
};

export default Calendar;
