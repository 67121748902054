import React, { useState } from 'react';
import CalendarContainer from './CalendarContainer';

export const fullSchedule = [
  {
    arrival: '9:00',
    fika: '10:00',
    lunch: '12:00',
    fika2: '14:00',
    ends: '17:00',
  },
  {
    arrival: '9:00',
    fika: '10:00',
    lunch: '12:00',
    fika2: '14:00',
    ends: '17:00',
  },
];

export const amSchedule = [
  {
    arrival: '9:00',
    fika: '10:00',
    lunch: '12:00',
    ends: '13:00',
  },
];

export const pmSchedule = [
  {
    arrival: '13:00',
    lunch: '14:00',
    fika2: '15:00',
    ends: '17:00',
  },
];

const ScheduleContainer = (props) => {
  const [schedule, setSchedule] = useState(fullSchedule);
  const [guidedTourTime, setGuidedTourTime] = useState('first_before');

  const changeSchedule = (e, day) => {
    let { name, value } = e.target;
    const currentChangedName = name
      .split(' ')
      .join('_')
      .toLowerCase();

    if (name === 'guided tour') {
      return setGuidedTourTime(value);
    }

    let keys = Object.keys(schedule[day - 1]);

    let loc = keys.indexOf(currentChangedName);

    let newSchedule = { ...schedule[day - 1] };

    for (let key in schedule[day - 1]) {
      let currentIndex = keys.indexOf(key);
      if (currentIndex === loc) {
        newSchedule[key] = value;
      } else if (
        currentIndex < loc &&
        Date.parse(`01/01/2020 ${value}`) <
          Date.parse(`01/01/2020 ${schedule[day - 1][key]}`)
      ) {
        newSchedule[key] = value;
      } else if (
        currentIndex > loc &&
        Date.parse(`01/01/2020 ${value}`) >
          Date.parse(`01/01/2020 ${schedule[day - 1][key]}`)
      ) {
        newSchedule[key] = value;
      }
    }

    if (day === 1) {
      setSchedule([
        {
          ...newSchedule,
        },
        { ...schedule[1] },
      ]);
    } else {
      setSchedule([
        { ...schedule[0] },
        {
          ...newSchedule,
        },
      ]);
    }
  };
  return (
    <CalendarContainer
      schedule={schedule}
      setSchedule={setSchedule}
      guidedTourTime={guidedTourTime}
      setGuidedTourTime={setGuidedTourTime}
      changeSchedule={changeSchedule}
      {...props}
    />
  );
};

export default ScheduleContainer;
