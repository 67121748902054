import { Link, Redirect, withRouter } from 'react-router-dom';
import React from 'react';
import H2 from './_common/H2';
import styled from 'styled-components';
import Button from './_common/Button';


const Room = ({ room, history }) => {
  return <RoomWrapper>
    <H2>{room.roomName}</H2>
    <div>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, asperiores atque culpa ea id illum ipsa non nulla odio optio temporibus ullam? Aliquam at blanditiis fugit in molestias porro quos.</p>
      <Button
        title="Book"
        func={() => history.push(`${room.slug}`)}
      />
    </div>
    {/*<Link to={`${room.slug}`}>
      Book
    </Link>*/}

  </RoomWrapper>;
};

const RoomWrapper = styled.div`
  //min-width: 100px;
  position: relative;
  //text-align: left;
  margin-bottom: 45px;
`;

export default withRouter(Room);
