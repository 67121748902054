import React from 'react';
import OptionRow from './OptionRow';
import P from './_common/P';
import H3 from './_common/H3';
import styled from 'styled-components';

const AddonsBlock = ({
  appWidth,
  addons,
  guests,
  packages,
  allergies,
  lang,
  addonsPrices,
  selectedDate,
  bigText
}) => {
  return (
    <>
      {!addons.full_day &&
        !addons.guided &&
        (!addons.dinner ||
          (addons.dinner && packages.every((el) => el.number === 0))) && (
          bigText ? <StyledH3>None</StyledH3> : <StyledP>None</StyledP>
        )}
      {addons.full_day && (
        <OptionRow
          appWidth={appWidth}
          name={addonsPrices.full_day.name}
          price={addonsPrices.full_day.price_excl_tax}
          caption={addonsPrices.full_day.description}
          guests={selectedDate[0].dayPart === 'two' ? guests * 2 : guests}
          total={
            selectedDate[0].dayPart === 'two'
              ? addonsPrices.full_day.price_excl_tax * guests * 2
              : addonsPrices.full_day.price_excl_tax * guests
          }
        />
      )}
      {addons.guided && (
        <OptionRow
          appWidth={appWidth}
          name={addonsPrices.guided.name}
          price={addonsPrices.guided.price_excl_tax}
          caption={addonsPrices.guided.description}
          total={addonsPrices.guided.price_excl_tax}
        />
      )}
      {addons.dinner &&
        packages.map((el) => {
          return (
            el.number > 0 && (
              <OptionRow
                key={el.name}
                appWidth={appWidth}
                name={el.name}
                price={el.packages[el.value - 1].price_excl_tax}
                caption={el.packages[el.value - 1].name}
                guests={el.number}
                total={el.packages[el.value - 1].price_excl_tax * el.number}
                pack
              />
            )
          );
        })}
      <OptionRow
        name={lang === 'en' ? 'Allergies' : 'Allergier'}
        caption={(addons.allergies && allergies) || 'None'}
        lang={lang}
        bigText={bigText}
      />
    </>
  );
};

export default AddonsBlock;

const StyledP = styled(P)`
  margin-top: -10px !important;
  margin-bottom: 25px !important;
`;

const StyledH3 = styled(H3)`
  margin-bottom: 45px !important;
`;
