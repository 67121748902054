import axios from 'axios';
import { blockedDates, excludeBlockedDates } from './blockedDates';

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

const defaultId = 3;
const caspecoUrl = `${process.env.REACT_APP_CASPECO_URL}`;
const SuiteAPIKey = `${process.env.REACT_APP_SUITE_API_KEY}`;
const suiteUrl = `${process.env.REACT_APP_SUITE_URL}`;

export const getInfo = (date, id) => {
  return axios
    .get(
      `${caspecoUrl}AvailableTimes?date=${date}&unitId=14&sectionId=${
        id ? id : defaultId
      }`,
      {
        cancelToken: source.token,
        headers: {
          accept: 'application/json',
          system: 'fotsto',
        },
      },
    )
    .then((res) => {
      return res.data[0].sections[0].timeSets[0];
    })
    .catch(console.log);
};

export const getDayInfo = (date, id) => {
  return axios
    .get(
      `${caspecoUrl}AvailableTimes?date=${date}&unitId=14&sectionId=${
        id ? id : defaultId
      }`,
      {
        cancelToken: source.token,
        headers: {
          accept: 'application/json',
          system: 'fotsto',
        },
      },
    )
    .then((res) => res.data[0].sections[0].timeSets)
    .catch(console.log);
};

export const getAvailableSeats = (dates, id) => {
  return axios
    .get(
      `${caspecoUrl}DayStates?fromDate=${dates[0].day.format(
        'YYYY-MM-DD',
      )}&toDate=${dates[dates.length - 1].day
        .clone()
        .add(3, 'day')
        .format('YYYY-MM-DD')}&unitId=14&sectionId=${
        id ? id : defaultId
      }&includeAvailableSeats=true`,
      {
        cancelToken: source.token,
        headers: {
          accept: 'application/json',
          system: 'fotsto',
        },
      },
    )
    .then((res) => {      
      return {
        ...res,
        data: excludeBlockedDates(res.data),
      };
    }).then(res => {
      return res;
    }) 
    .catch(console.log);
};

export const createBooking = (data, lang) => {
  return axios.post(
    `${suiteUrl}venue_bookings`,
    { venue_booking: data },
    {
      headers: {
        accept: 'application/json',
        'x-api-key': SuiteAPIKey,
        'Accept-Language': lang,
      },
    },
  );
};

// export const finallizeBooking = (id, counter = 0) => {
//   return axios
//     .post(
//       `${suiteUrl}venue_bookings/${id}/finalize`,
//       {},
//       {
//         headers: {
//           accept: 'application/json',
//           'x-api-key': SuiteAPIKey,
//         },
//       },
//     )
//     .catch((e) => {
//       if (counter > 5) {
//         throw e;
//       }
//       return finallizeBooking(id, counter + 1);
//     });
// };

export const getVenueInfo = (id, lang) => {
  return axios.get(`${suiteUrl}venues/${id}`, {
    headers: {
      accept: 'application/json',
      'x-api-key': SuiteAPIKey,
      'Accept-Language': lang,
    },
  });
};
