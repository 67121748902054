import React, { useState } from 'react';
import Container from '../components/Container';
import SectionContainer from '../components/SectionContainer';
import ButtonsRow from '../components/ButtonsRow';
import AbsoluteContainer from '../components/AbsoluteContainer';
import styled from 'styled-components';
import H2 from '../components/_common/H2';
import Input from '../components/_common/Input';
import RadioSelect from '../components/_common/RadioSelect';
import Checkbox from '../components/_common/Checkbox';
import { useForm } from 'react-hook-form';
import { email, onlyDigits, onlyLetters } from '../helpers/regex';
import CreditCardPayment from '../components/CreditCardPayment';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import P from '../components/_common/P';

const Payment = ({
  closeApp,
  setCurrPage,
  book,
  error,
  details,
  setDetails,
  total,
  lang,
  isBlocked,
  isPaying,
  isPaid,
  setIsPaying,
  appWidth,
}) => {
  const [radio, setRadio] = useState('invoice');
  const { handleSubmit, register, errors } = useForm();
  const stripe = useStripe();
  const elements = useElements();

  const submit = async (user) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    try {
      setIsPaying(true);
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            postal_code: user.postal_code,
          },
          name: user.card_name,
        },
      });
      // this is how payment info will look like

      const payment_info = {
        sources: [
          {
            type: 'stripe',
            amount: +total.withVAT,
            payment_method: payload.paymentMethod.id,
          },
        ],
      };

      book(user, payment_info);
    } catch (e) {
      setIsPaying(false);
    }
  };

  return (
    <AbsoluteContainer>
      <Container
        title={lang === 'en' ? 'Details & Payment' : 'Uppgifter & Betalning'}
        closeApp={
          isPaying || isPaid
            ? () => {}
            : () => {
                setDetails(false);
                closeApp();
              }
        }>
        <form
          onSubmit={
            radio === 'invoice' ? handleSubmit(book) : handleSubmit(submit)
          }>
          <SectionContainer>
            <FlexWrapper>
              <LeftBlock>
                <Title>{lang === 'en' ? 'Details' : 'UPPGIFTER'}</Title>
                <InputWrapper first>
                  <Input
                    title={lang === 'en' ? 'Surname' : 'Förnamn'}
                    name="first_name"
                    ref={register({
                      required: 'Required',
                      minLength: {
                        value: 2,
                        message: 'First name is too short',
                      },
                      maxLength: {
                        value: 20,
                        message: 'First name is too long',
                      },
                      pattern: {
                        value: onlyLetters,
                        message: 'Invalid name',
                      },
                    })}
                    error={errors.first_name}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    title={lang === 'en' ? 'Last name' : 'Efternamn'}
                    name="last_name"
                    ref={register({
                      required: 'Required',
                      minLength: {
                        value: 2,
                        message: 'Last name is too short',
                      },
                      maxLength: {
                        value: 20,
                        message: 'Last name is too long',
                      },
                      pattern: {
                        value: onlyLetters,
                        message: 'Invalid last name',
                      },
                    })}
                    error={errors.last_name}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Input
                    title={lang === 'en' ? 'Phone number' : 'Telefon'}
                    name="phone"
                    ref={register({
                      required: 'Required',
                      minLength: {
                        value: 5,
                        message: 'Phone number is too short',
                      },
                      maxLength: {
                        value: 20,
                        message: 'Phone number is too long',
                      },
                      pattern: {
                        value: onlyDigits,
                        message: 'Invalid phone number',
                      },
                    })}
                    error={errors.phone}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Input
                    title="E-mail"
                    name="email"
                    ref={register({
                      required: 'Required',
                      maxLength: {
                        value: 50,
                        message: 'Email is too long',
                      },
                      pattern: {
                        value: email,
                        message: 'Invalid email address',
                      },
                    })}
                    error={errors.email}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Input
                    title={lang === 'en' ? 'Invoice e-mail (if other than above)' : 'Faktura e-mail (om annan än ovan)'}
                    name="invoice_email"
                    ref={register({
                      // required: 'Required',
                      maxLength: {
                        value: 50,
                        message: 'Email is too long',
                      },
                      pattern: {
                        value: email,
                        message: 'Invalid email address',
                      },
                    })}
                    error={errors.invoice_email}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Input
                    title={lang === 'en' ? 'Reference' : 'Kostnadsställe eller referens'}
                    name="reference"
                    ref={register({
                      // required: 'Required',
                      maxLength: {
                        value: 250,
                        message: 'Reference is too long',
                      },
                    })}
                    error={errors.reference}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Input
                    title={lang === 'en' ? 'Company' : 'Företag'}
                    name="company"
                    ref={register({
                      required: 'Required',
                      minLength: {
                        value: 2,
                        message: 'Company name is too short',
                      },
                      maxLength: {
                        value: 100,
                        message: 'Company name is too long',
                      },
                    })}
                    error={errors.company}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Input
                    title={
                      lang === 'en' ? 'Organization No.' : 'Organisationsnr.'
                    }
                    name="company_org_number"
                    autocomplete="new-password"
                    ref={register({
                      required: 'Required',
                      minLength: {
                        value: 2,
                        message: 'Organization number is too short',
                      },
                      pattern: {
                        value: onlyDigits,
                        message: 'Invalid number',
                      },
                    })}
                    error={errors.company_org_number}
                  />
                </InputWrapper>
              </LeftBlock>
              {appWidth < 740 && (
                <CheckboxWrapper>
                  <Checkbox
                    title={
                      lang === 'en'
                        ? 'I agree to allow Fotografiska store and process my personal data. '
                        : 'Jag ger mitt medgivande till att Fotografiska får lagra och behandla min personliga data. '
                    }
                    linkText={lang === 'en' ? 'Read more' : 'Läs mer'}
                    link="https://www.fotografiska.com/sto/privacy-policy/"
                    value={details}
                    change={setDetails}
                    unavailable={isPaid || isPaying}
                  />
                </CheckboxWrapper>
              )}
              <RightBlock>
                <Title payment>
                  {lang === 'en' ? 'Payment options' : 'BETALNING'}
                </Title>
                <RadioWrapper>
                  {(isPaid || isPaying) && <GreyOutBlock />}
                  <RadioSelect
                    value={radio}
                    selectValue={setRadio}
                    selectors={[
                      {
                        title: lang === 'en' ? 'Invoice' : 'Faktura',
                        value: 'invoice',
                      },
                      {
                        title:
                          lang === 'en'
                            ? 'Direct payment using credit card'
                            : 'Direktbetalning med kort',
                        value: 'credit',
                      },
                    ]}
                  />
                </RadioWrapper>
                {radio === 'credit' && (
                  <CreditCardPayment
                    total={total}
                    lang={lang}
                    appWidth={appWidth}
                    register={register}
                    errors={errors}
                    isBlocked={isBlocked || !details}
                    isPaid={isPaid}
                    isPaying={isPaying}
                  />
                )}
                {radio === 'invoice' && lang == 'en' && (
                  <PaymentOptionNoticeBlock>
                    Please note that for non-Swedish organisations and companies, a full pre-payment must be done no later than 30 days prior to the meeting or event.
                  </PaymentOptionNoticeBlock>
                )}
              </RightBlock>
            </FlexWrapper>
            {appWidth >= 740 && (
              <CheckboxWrapper>
                <Checkbox
                  title={
                    lang === 'en'
                      ? 'I agree to allow Fotografiska store and process my personal data. '
                      : 'Jag ger mitt medgivande till att Fotografiska får lagra och behandla min personliga data. '
                  }
                  linkText={lang === 'en' ? 'Read more' : 'Läs mer'}
                  link="https://www.fotografiska.com/sto/privacy-policy/"
                  value={details}
                  change={setDetails}
                  unavailable={isPaid || isPaying}
                />
              </CheckboxWrapper>
            )}
          </SectionContainer>
          <SectionContainer title="">
            <ButtonsRow
              back="summary"
              next="confirmation"
              funcBack={(page) => {
                setDetails('flag');
                setCurrPage(page);
              }}
              funcNext={
                isPaid &&
                ((page) => {
                  setCurrPage(page);
                })
              }
              error={error}
              lang={lang}
              unavailable={
                !details || isBlocked || (radio !== 'invoice' && !isPaid)
              }
              submit
              backUnavailable={isPaid || isPaying}
              appWidth={appWidth}
              animating={isPaying && radio === 'invoice'}
            />
          </SectionContainer>
        </form>
      </Container>
    </AbsoluteContainer>
  );
};

export default Payment;

const PaymentOptionNoticeBlock = styled(P)`
  padding-top: ${(props) => (props.theme.width < 740 ? '22px' : '18px')};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.theme.width < 740 && 'column'};
`;

const Title = styled(H2)`
  margin-top: ${(props) =>
    props.payment && props.theme.width < 740 ? '40px' : '0'};
`;

const LeftBlock = styled.div`
  flex: 1;
  margin-right: ${(props) => (props.theme.width >= 740 ? '80px' : '0')};
`;

const RightBlock = styled.div`
  flex: 1;
  margin-left: ${(props) => (props.theme.width >= 740 ? '80px' : '0')};
`;

const CheckboxWrapper = styled.div`
  margin-top: 46px;
  margin-bottom: 42px;
`;

const RadioWrapper = styled.div`
  position: relative;
  margin: 53px 0;
  margin-top: ${(props) => props.theme.width < 740 && '40px'};
  margin-bottom: ${(props) => (props.theme.width < 740 ? '0' : '40px')};
`;

const GreyOutBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  // background-color: rgba(255, 255, 255, 0.7);
  z-index: 5;
`;

const InputWrapper = styled.div`
  margin-top: ${(props) => (props.first ? '38px' : '25px')};
  margin-top: ${(props) => props.theme.width < 740 && '25px'};
  margin-top: ${(props) => props.first && props.theme.width < 740 && '20px'};
  width: auto;
`;
