import React from 'react';
import styled from 'styled-components';

const AbsoluteContainer = ({ children }) => (
  <Absolute>
    {children}
  </Absolute>
);

export default AbsoluteContainer;

const Absolute = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999997;
`;
