import React from 'react';
import H3 from './_common/H3';
import styled from 'styled-components';
import { dayParts } from './CalendarBlock';
import { getRoomPriceByPart } from '../helpers/roomPrice';

const VenueRow = ({
  guests,
  appWidth,
  dayPart,
  selectedDate,
  roomPrice,
  roomName,
  noGuests,
  currPage,
  lang,
}) => {
  const generateDate = () => {
    if (dayPart === 'two') {
      if (selectedDate[0].date.isSame(selectedDate[1].date, 'month')) {
        return `${selectedDate[0].date
          .locale(lang === 'en' ? 'en-gb' : 'sv')
          .format('D')} & ${selectedDate[1].date
          .locale(lang === 'en' ? 'en-gb' : 'sv')
          .format('D MMMM YYYY')}`;
      } else {
        return `${selectedDate[0].date
          .locale(lang === 'en' ? 'en-gb' : 'sv')
          .format('D MMMM')} & ${selectedDate[1].date
          .locale(lang === 'en' ? 'en-gb' : 'sv')
          .format('D MMMM YYYY')}`;
      }
    }
    return selectedDate[0].date
      .locale(lang === 'en' ? 'en-gb' : 'sv')
      .format('D MMMM YYYY');
  };
  return (
    <Details>
      <Date>
        <Names noGuests={noGuests}>
          <Name>
            <H3>{roomName && roomName}</H3>
          </Name>
          <Name>
            <H3>{generateDate()}</H3>
          </Name>
          <Name>
            <H3>
              {dayPart === 'am' && dayParts(lang)[0].name}
              {dayPart === 'pm' && dayParts(lang)[1].name}
              {dayPart === 'day' && dayParts(lang)[2].name}
              {dayPart === 'two' && dayParts(lang)[3].name}
            </H3>
          </Name>
        </Names>
        {noGuests || (
          <Guests>
            <H3>
              {guests}{' '}
              {guests > 1
                ? lang === 'en'
                  ? 'guests'
                  : 'gäster'
                : lang === 'en'
                ? 'guest'
                : 'gäst'}
            </H3>
          </Guests>
        )}
      </Date>
      <PriceWrapper>
        {appWidth >= 740 && <EmptyBefore />}
        <Price currPage={currPage}>
          <H3>{getRoomPriceByPart(roomPrice, dayPart).price} kr</H3>
        </Price>
        {appWidth >= 740 && <EmptyAfter />}
      </PriceWrapper>
    </Details>
  );
};

export default VenueRow;

const EmptyBefore = styled.div`
  flex: 1;
`;
const EmptyAfter = styled.div`
  flex: 3;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  ${(props) => props.theme.width < 740 && 'position: absolute; right: 0px; bottom: 10px;'};
`;
const Details = styled.div`
  display: flex;
  width: 100%;
`;

const Date = styled.div`
  flex: ${(props) => props.theme.width < 740 ? 3 : 1};
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  flex: ${(props) => props.theme.width < 740 ? 2 : 1};
  text-align: right;
  margin-left: 5px;
`;

const Name = styled.div`
  text-transform: capitalize;
  margin-right: 15px;
`;

const Names = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => (props.noGuests ? '15px' : '24px')};
`;

const Guests = styled.div`
  margin-bottom: 12px;
`;
