import moment from 'moment';
import { bookingAfterDays } from './chooseType';

export const makeCalendar = (selectedMonth, setCurrMonth) => {
  const thisMonth = moment()
    .add(bookingAfterDays, 'days')
    .add(selectedMonth, 'month');
  setCurrMonth(thisMonth);

  let calendar = [];

  let startDay = thisMonth
    .clone()
    .startOf('month')
    .startOf('isoWeek');
  const endDay = thisMonth
    .clone()
    .endOf('month')
    .endOf('isoWeek');

  if (
    startDay
      .clone()
      .endOf('isoWeek')
      .format('D') === '1' ||
    startDay
      .clone()
      .endOf('isoWeek')
      .format('D') === '2'
  ) {
    startDay.add(1, 'week');
    if (
      endDay
        .clone()
        .subtract(4, 'weeks')
        .subtract(1, 'day')
        .isSameOrBefore(startDay)
    ) {
      endDay.add(1, 'week');
    }
  }

  if (
    endDay
      .clone()
      .subtract(4, 'weeks')
      .subtract(1, 'day')
      .isSameOrBefore(startDay)
  ) {
    endDay.add(1, 'week');
  }

  let date = startDay.clone().subtract(1, 'day');

  while (date.isBefore(endDay, 'day')) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => date.add(1, 'day').clone()),
    );
  }

  calendar.forEach((el) => {
    el.splice(-2, 2);
  });

  return [].concat(...calendar).map((day) => {
    return {
      day,
    };
  });
};
