import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import H1 from './_common/H1';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import addDays from 'date-fns/addDays';
import { enGB, sv } from 'date-fns/esm/locale';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Select from './_common/Select';
import guests from '../helpers/generateGuests';
import Button from './_common/Button';
import Day from './Day';
import MonthPicker from './MonthPicker';
import AvailabilityBlock from './AvailabilityBlock';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import P from './_common/P';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Loader } from '../images/loader.svg';
import { Container } from './_common/Container';
import { bookingAfterDays } from '../helpers/chooseType';

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    overflow: hidden;
  }
`;

const dayNames = (lang) =>
  lang === 'en'
    ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
    : ['MÅN', 'TIS', 'ONS', 'TOR', 'FRE'];
export const dayParts = (lang) =>
  lang === 'en'
    ? [
        { name: 'Half day AM', value: 'am' },
        { name: 'Half day PM', value: 'pm' },
        { name: 'Full day', value: 'day' },
        { name: 'Two days', value: 'two' },
      ]
    : [
        { name: 'Halvdag FM', value: 'am' },
        { name: 'Halvdag EM', value: 'pm' },
        { name: 'Heldag', value: 'day' },
        { name: 'Två dagar', value: 'two' },
      ];

const Popper = ({ children }) =>
  ReactDOM.createPortal(children, document.querySelector('body'));

const CalendarBlock = ({
  days,
  changeMonth,
  currMonth,
  selectDate,
  selectedDate,
  datepickerStartDate,
  availableSeats,
  changePickerMonth,
  isLoading,
  datepickerLoading,
  setDayPart,
  dayPart,
  setGuestNumber,
  setCurrPage,
  guestsNumber,
  availableDayParts,
  appWidth,
  title,
  roomNotAvailable,
  isFetching,
  lang,
  currPage,
  maxGuests,
  selectedRoom,
  setSelectedRoomSlug,
  roomSelector,
  rooms,
}) => {
  let isDesktop = appWidth >= 740;

  const [isOpened, setIsOpened] = useState(false);

  const DatepickerInputRef = React.forwardRef(({ value, onClick }, ref) => (
    <DatepickerInput
      ref={ref}
      type={'text'}
      placeholder={lang === 'en' ? 'Select date' : 'Välj datum'}
      value={value}
      readOnly
      onClick={onClick}
    />
  ));
  function handleOpen(date) {
    setIsOpened(true);
    if (selectedDate[0] && selectedDate[0].date) {
      changePickerMonth(selectedDate[0].date);
    } else {
      changePickerMonth(date);
    }
  }

  return (
    <Container appWidth={appWidth}>
      {((isOpened && appWidth < 740) || currPage !== 'calendar') && (
        <GlobalStyle />
      )}
      <Title>
        {lang === 'en' ? 'Book' : 'Boka'} {roomSelector ? '' : title}
      </Title>
      {roomNotAvailable ? (
        <RoomNotAvailable>Room Not Found</RoomNotAvailable>
      ) : (
        <>
          <PickersWrapper appWidth={appWidth} wide={roomSelector}>
            { roomSelector &&
              <PickerWrapper appWidth={appWidth}>
                <Select
                  height='55px'
                  name="Rooms"
                  options={rooms.map(room => ({name: room.roomName, value: room.slug}))}
                  changeSelect={e => setSelectedRoomSlug(e.target.value)}
                  value={selectedRoom.slug}
                  selectOption='Select room'
                />
              </PickerWrapper>
            }
            <PickerWrapper appWidth={appWidth} date>
              <DatePicker
                openToDate={selectedDate[0] && selectedDate[0].date ? null : datepickerStartDate}
                selected={
                  selectedDate[0] && selectedDate[0].date
                    ? selectedDate[0].date.toDate()
                    : null
                }
                onChange={(date) => {
                  setDayPart(
                    moment(date) > moment().add(8, 'day') ? 'day' : dayPart,
                    date,
                  );
                }}
                dateFormat="yyyy-MM-dd"
                customInput={<DatepickerInputRef />}
                calendarClassName={
                  datepickerLoading ? 'book-datepicker-loading' : null
                }
                minDate={addDays(new Date(), bookingAfterDays)}
                excludeDates={availableSeats}
                locale={lang === 'en' ? enGB : sv}
                disabledKeyboardNavigation={true}
                placeholderText="Select date"
                onMonthChange={(day) => {
                  changePickerMonth(day);
                }}
                onCalendarOpen={handleOpen}
                onCalendarClose={() => {
                  setIsOpened(false);
                }}
                shouldCloseOnSelect={true}
                popperModifiers={{
                  preventOverflow: {
                    enabled: false,
                  },
                }}
                popperContainer={appWidth < 740 && Popper}
                fixedHeight
              />
              <Icon icon={faCalendarAlt} />
            </PickerWrapper>
            <PickerWrapper appWidth={appWidth}>
              <Select
                height='55px'
                name="Day part"
                options={dayParts(lang)}
                changeSelect={setDayPart}
                value={dayPart}
                availability={availableDayParts}
              />
            </PickerWrapper>
            <GuestsWrapper appWidth={appWidth}>
              <Select
                height='55px'
                name="Guests"
                options={guests(maxGuests, lang)}
                changeSelect={setGuestNumber}
                value={guestsNumber}
              />
            </GuestsWrapper>
            <Button
              title={lang === 'en' ? 'next' : 'Nästa'}
              slug="next"
              page="venue"
              func={setCurrPage}
              unavailable={
                (selectedDate[0] && !selectedDate[0].date) || isFetching
              }
            />
          </PickersWrapper>
          {isDesktop && (
            <InnerWrapper>
              <CalendarWrapper>
                {isLoading && (
                  <GreyOutBlock>
                    <Spinner />
                  </GreyOutBlock>
                )}
                {dayNames(lang).map((day, index) => (
                  <ColumnHeading key={index}>{day}</ColumnHeading>
                ))}
                {days.map((day, index) =>
                  index !== 5 || index !== 6 ? (
                    <Day
                      key={index}
                      date={day}
                      selectDate={selectDate}
                      selectedDate={selectedDate}
                      isLoading={isLoading}
                      width={appWidth}
                      lang={lang}
                    />
                  ) : null,
                )}
              </CalendarWrapper>
              <MonthPicker
                changeMonth={isLoading ? () => {} : changeMonth}
                currMonth={currMonth}
                lang={lang}
              />
              <AvailabilityContainer>
                <AvailabilityBlock
                  slug="available"
                  title={lang === 'en' ? 'available' : 'Tillgänglig'}
                />
                <AvailabilityBlock
                  slug="selected"
                  title={lang === 'en' ? 'selected' : 'Vald'}
                />
                <AvailabilityBlock
                  slug="unavailable"
                  title={lang === 'en' ? 'unavailable' : 'Ej tillgänglig'}
                />
              </AvailabilityContainer>
            </InnerWrapper>
          )}
        </>
      )}
    </Container>
  );
};

export default CalendarBlock;

const DatepickerInput = styled.input`
  font-family: ${(props) => props.theme.mainFont} !important;
`

const Overlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const Title = styled(H1)`
  margin-top: 10px;
  margin-bottom: 16px;
`;

const RoomNotAvailable = styled(P)`
  color: darkRed;
  font-size: 24px;
`;

const ColumnHeading = styled(P)`
  text-transform: uppercase;
  text-align: center;
`;

const CalendarWrapper = styled.div`
  display: grid;
  overflow: hidden;
  padding-right: 1px;
  padding-bottom: 1px;
  position: relative;
  margin-top: 40px;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: 40px auto;
`;

const GreyOutBlock = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const AvailabilityContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: flex-row;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const PickerWrapper = styled.div`
  height: 55px;
  position: relative;
  width: ${(props) => (props.appWidth >= 740 ? `160px` : `100%`)};
  margin-right: 0;
  margin-bottom: ${(props) => (props.appWidth >= 740 ? `0` : `10px`)};
  border-bottom: ${(props) => props.date && `1px solid ${props.theme.black}`};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Loader)`
  animation: ${rotate} 0.9s linear infinite;
  margin-top: -40px;
`;

const GuestsWrapper = styled.div`
  width: ${(props) => (props.appWidth >= 740 ? `100px` : `100%`)};
  margin-right: 0;
  margin-bottom: ${(props) => (props.appWidth >= 740 ? `0` : `40px`)};
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  color: ${(props) => props.theme.black};
  top: 20px;
  right: 0;
`;

const PickersWrapper = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => (props.theme.width < 740 ? 100 : (props.wide ? 100 : 80))}%;
  flex-direction: ${(props) => (props.appWidth >= 740 ? 'row' : 'column')};
  align-items: flex-end;
  justify-content: space-between;
`;

const InnerWrapper = styled.div`
  width: 100%;
`;
