import React from 'react';
import H3 from './_common/H3';
import Select from './_common/Select';
import styled from 'styled-components';
import { generateGuided } from '../helpers/generateGuided';

const ScheduleOption = ({
  appWidth,
  title,
  slug,
  options,
  text,
  changeSchedule,
  day,
  addons,
  value,
  dayPart,
  lang,
}) => {
  return (
    <ScheduleRow title={slug}>
      {appWidth < 740 && (
        <NameWrapper en={lang === 'en'}>
          <StyledHeading>
            {slug === 'fika' && +value.split(':')[0] <= 9
              ? lang === 'en'
                ? 'Breakfast'
                : 'Frukost'
              : title}
          </StyledHeading>
        </NameWrapper>
      )}
      <BlocksWrapper guided={slug === 'guided tour'}>
        <LeftBlock guided={slug === 'guided tour'} am={dayPart === 'am' || dayPart === 'pm'} isGuided={addons.guided}>
          {appWidth >= 740 && (
            <NameWrapper en={lang === 'en'}>
              <H3>
                {slug === 'fika' && +value.split(':')[0] <= 9
                  ? lang === 'en'
                    ? 'Breakfast'
                    : 'Frukost'
                  : title}
              </H3>
            </NameWrapper>
          )}
          <SelectWrapper>
            <StyledSelect
              title={title}
              guided={slug === 'guided tour'}
              am={dayPart === 'am' || dayPart === 'pm'}>
              {dayPart === 'am' || dayPart === 'pm' ? (
                <H3>
                  {
                    generateGuided(lang, dayPart).find(
                      (el) => el.value === value,
                    ).name
                  }
                </H3>
              ) : (
                <Select
                  options={options}
                  changeSelect={changeSchedule}
                  name={slug}
                  day={day}
                  value={value}
                />
              )}
            </StyledSelect>
            {slug.toLowerCase() !== 'guided tour' && appWidth >= 740 && (
              <EmptyBox />
            )}
          </SelectWrapper>
        </LeftBlock>
        {appWidth >= 740 && <MidBlock />}
        <RightBlock guided={slug === 'guided tour'}>
          <H3>
            {slug === 'fika' && +value.split(':')[0] <= 9
              ? lang === 'en'
                ? 'Served in the restaurant'
                : 'Serveras i restaurangen'
              : text}
          </H3>
        </RightBlock>
      </BlocksWrapper>
    </ScheduleRow>
  );
};

export default ScheduleOption;

const ScheduleRow = styled.div`
  display: flex;
  height: ${(props) => props.theme.width >= 740 && '45px'};
  flex-direction: ${(props) => props.theme.width < 740 && 'column'};
  align-items: ${(props) =>
    props.theme.width >= 740 ? 'baseline' : 'flex-start'};
  margin-bottom: ${(props) => (props.theme.width < 740 ? '25px' : '0px')};
  margin-top: ${(props) =>
    props.title.toLowerCase() === 'arrival' &&
    props.theme.width < 740 &&
    '40px'};
`;

const LeftBlock = styled.div`
  flex: ${(props) => props.theme.width < 740 ? '0' : (props.isGuided ? 10 : 7)};
  margin-right: ${(props) => props.theme.width < 740 && '20px'};
  display: flex;
  align-items: ${props => props.guided && props.am ? 'center' : 'baseline'};
`;

const RightBlock = styled.div`
  flex: ${(props) => (props.theme.width < 740 ? '13' : '12')};
`;

const NameWrapper = styled.div`
  flex: ${(props) => props.en ? '10' : '12'};
  margin-right: ${(props) => props.theme.width >= 740 && '10px'};
`;

const StyledSelect = styled.div`
  width: ${(props) => (!props.guided ? '80px' : 'auto')};
  margin-bottom: ${(props) =>
    props.guided && props.theme.width < 740 && '20px'};
  margin-top: ${(props) => props.am && props.theme.width < 740 && '20px'};
`;

const SelectWrapper = styled.div`
  flex: 10;
  display: flex;
`;

const EmptyBox = styled.div`
  flex: 1;
`;

const BlocksWrapper = styled.div`
  height: ${(props) =>
    props.guided && props.theme.width < 740 ? 'auto' : '45px'};
  width: 100%;
  display: flex;
  align-items: center;
  align-items: ${(props) => props.theme.width < 740 && 'baseline'};
  flex-direction: ${(props) =>
    props.guided && props.theme.width < 740 && 'column'};
`;

const StyledHeading = styled(H3)`
  margin: 0 !important;
`;

const MidBlock = styled.div`
  flex: 1;
`;
