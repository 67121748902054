import React from 'react';
import Container from '../components/Container';
import SectionContainer from '../components/SectionContainer';
import AbsoluteContainer from '../components/AbsoluteContainer';
import P from '../components/_common/P';
import H3 from '../components/_common/H3';
import styled from 'styled-components';
import TotalBlock from '../components/Total';
import Button from '../components/_common/Button';
import AddonsBlock from '../components/AddonsBlock';
import ScheduleBlock from '../components/ScheduleBlock';
import moment from 'moment';
import VenueRow from '../components/VenueRow';
import ReactMarkdown from 'react-markdown';

const Confirmation = ({
  schedule,
  selectedDate,
  appWidth,
  closeApp,
  userInfo,
  total,
  allergies,
  addons,
  guests,
  packages,
  addonsPrices,
  lang,
  guidedTourTime,
  roomName,
  dayPart,
  roomPrice,
  currPage,
}) => {
return (
  <AbsoluteContainer>
    <Container
      title={lang === 'en' ? 'Confirmation' : 'Bekräftelse'}
      closeApp={closeApp}>
      <SectionContainer>
        <DetailsWrapper>
          <UserDetails>
            <TextRow>{userInfo.first_name}</TextRow>
            <TextRow>{userInfo.last_name}</TextRow>
            <TextRow>{userInfo.phone}</TextRow>
            <TextRow>{userInfo.email}</TextRow>
            {userInfo.invoice_email && <TextRow>{userInfo.invoice_email}</TextRow>}
            {userInfo.reference && <TextRow>{userInfo.reference}</TextRow>}
            <TextRow>{userInfo.company}</TextRow>
            <TextRow>{userInfo.company_org_number}</TextRow>
          </UserDetails>
          <AddressWrapper>
            <TextRow>Fotografiska Stockholm AB</TextRow>
            <TextRow>Stadsgårdhamnen 22</TextRow>
            <TextRow>116 45 Stockholm</TextRow>
            <TextRow>08 509 00 540</TextRow>
            <BottomAddress>
              <TextRow>Fotografiska Meetings & Events</TextRow>
              <TextRow>event@fotografiska.se</TextRow>
            </BottomAddress>
          </AddressWrapper>
        </DetailsWrapper>
        <BookingDate>
          <Name>
            <H3>
              {lang === 'en' ? 'Booking date: ' : 'Bokningsdatum: '}
              {moment()
                .locale(lang === 'en' ? 'en-gb' : 'sv')
                .format('D MMMM YYYY')}
            </H3>
          </Name>
        </BookingDate>
      </SectionContainer>
      <SectionContainer title={lang === 'en' ? 'Venue' : 'LOKAL'}>
        <VenueRow
          appWidth={appWidth}
          roomName={roomName}
          dayPart={dayPart}
          selectedDate={selectedDate}
          roomPrice={roomPrice}
          guests={guests}
          lang={lang}
        />
      </SectionContainer>
      <SectionContainer title={lang === 'en' ? 'Add ons' : 'TILLÄGG'}>
        <AddonsBlock
          appWidth={appWidth}
          addons={addons}
          allergies={allergies}
          guests={guests}
          packages={packages}
          addonsPrices={addonsPrices}
          selectedDate={selectedDate}
          lang={lang}
          bigText
        />
      </SectionContainer>
      <SectionContainer title={lang === 'en' ? 'total price' : 'TOTALT PRIS'}>
        <TotalBlock appWidth={appWidth} total={total} lang={lang} />
      </SectionContainer>
      <SectionContainer title={lang === 'en' ? 'schedule' : 'Schema'}>
        {selectedDate.map((day, index) => (
          <ScheduleBlock
            key={index}
            day={day}
            selectedDay={index}
            schedule={schedule}
            addons={addons}
            selectedDate={selectedDate}
            lang={lang}
            guidedTourTime={guidedTourTime}
            addonsPrices={addonsPrices}
            appWidth={appWidth}
          />
        ))}
      </SectionContainer>
      <SectionContainer>
        <BottomWrapper>
          <ReactMarkdownWrapper>
            {lang === 'en'
              ? `A copy of this confirmation will be emailed to ${userInfo.email}

For payments using invoice, terms are 20 days net. VAT will be added to all the prices above unless otherwise stated. For non-Swedish organisations and companies, full payment should be done no later than 30 days prior to the meeting or event.

Cancellation must be done in writing and sent to [event@fotografiska.se](mailto:event@fotografiska.se).

For cancellations done within 60-30 days prior to the conference/event, a fee of 25% of the total reservation value will be charged at the time of cancellation. For cancellations done within 30 to 15 days of the conference/event, 50% of the total reservation will be charged, and for cancellations done within 14 days of the conference/event, the full reservation value will be charged.`
              : `En kopia av den här bekräftelsen skickas till ${userInfo.email}

För fakturabetalningar är betalningsvillkoret 20 dagar netto. Moms tillkommer på samtliga priser ovan om inget annat anges.

Avbokning ska ske skriftligt och skickas till [event@fotografiska.se](mailto:event@fotografiska.se).

Vid avbokning 60-31 dagar innan konferensen/evenemanget utgår en bokningsavgift på 25% av bokningens värde. Vid avbokning 30-15 dagar innan konferensen/evenemanget utgår en bokningsavgift på 50% av bokningens värde, och vid avbokning 14 dagar innan eller senare debiteras kunden 100% av bokningens värde. `}
          </ReactMarkdownWrapper>
          <ButtonWrapper>
            <Button
              title={lang === 'en' ? 'Close' : 'Stäng'}
              slug="close"
              func={closeApp}
            />
          </ButtonWrapper>
        </BottomWrapper>
      </SectionContainer>
    </Container>
  </AbsoluteContainer>
)};

export default Confirmation;

const UserDetails = styled.div`
  flex: 4;
  margin-bottom: ${(props) => props.theme.width < 740 && '55px'};
`;

const AddressWrapper = styled.div`
  flex: 3;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.theme.width >= 740 ? 'row' : 'column')};
`;

const TextRow = styled(H3)`
  margin-top: 10px;
  line-height: 26px !important;
`;

const BookingDate = styled.div`
  margin-top: 70px !important;
  display: flex;
`;

const BottomAddress = styled.div`
  margin-top: 35px;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.theme.width < 740 && 'column-reverse'};
  justify-content: space-between;
`;


const ReactMarkdownWrapper = styled(ReactMarkdown)`
  flex: 1;
  max-width: 640px;
  flex-grow: 100;
  font-family: 'minion-pro', sans-serif !important;
  font-size: 15px;
  line-height: 19px !important;
  color: ${(props) => props.theme.black} !important;
  text-decoration: none !important;
  margin: 0 !important;
`;

const ButtonWrapper = styled.div`
  padding-left: 40px;
  justify-content: flex-end;
  align-self: flex-start;
  text-align: right;
  display: flex;
  flex: 1;
  width: ${(props) => props.theme.width < 740 && '100%'};
  box-sizing: ${(props) => props.theme.width < 740 && 'border-box'};
  margin-bottom: ${(props) => props.theme.width < 740 && '40px'};
`;
const Name = styled.div`
  margin-right: 20px;
`;
