import React from 'react';
import P from './_common/P';
import styled from 'styled-components';

const AvailabilityBlock = ({ title, slug }) => (
  <Availability>
    <AvailabilityColor title={slug} />
    <P>{title}</P>
  </Availability>
);

export default AvailabilityBlock;

const Availability = styled.div`
  display: flex;
  flex-direction: flex-row;
  align-items: center;
  margin-right: 35px;
  text-transform: capitalize;
`;

const AvailabilityColor = styled.div`
  width: 58px;
  height: 40px;
  margin-right: 20px;
  border: 1px solid ${(props) => props.theme.black};
  background-color: ${(props) => {
    switch (props.title) {
      case 'selected':
        return props.theme.blue;
      case 'unavailable':
        return props.theme.inactiveGrey;
      default:
        return props.theme.white;
    }
  }};
`;
