const guestsNumber = (number, lang) => {
  let guests = [];
  for (let i = 1; i <= number; i++) {
    if (i > 1) {
      guests.push({ name: `${i} ${lang === 'en' ? 'guests' : 'gäster'}`, value: i });
    } else {
      guests.push({ name: `${i} ${lang === 'en' ? 'guest' : 'gäst'}`, value: i });
    }
  }
  return guests;
};

export default guestsNumber;
