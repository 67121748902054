const guidedTour = [
  { name: 'Day 1, before lunch', value: 'first_before' },
  { name: 'Day 1, after lunch', value: 'first_after' },
  { name: 'Day 2, before lunch', value: 'second_before' },
  { name: 'Day 2, after lunch', value: 'second_after' },
];

const guidedTourSV = [
  { name: 'Day 1, innan lunch', value: 'first_before' },
  { name: 'Day 1, efter lunch', value: 'first_after' },
  { name: 'Day 2, innan lunch', value: 'second_before' },
  { name: 'Day 2, efter lunch', value: 'second_after' },
];

const oneDayGuidedTour = [
  { name: 'Before lunch', value: 'first_before' },
  { name: 'After lunch', value: 'first_after' },
];

const oneDayGuidedTourSV = [
  { name: 'Innan lunch', value: 'first_before' },
  { name: 'Efter lunch', value: 'first_after' },
];

export const generateGuided = (lang, days) => {
  if (days === 'two') {
    return lang === 'en' ? guidedTour : guidedTourSV;
  }

  return lang === 'en' ? oneDayGuidedTour : oneDayGuidedTourSV;
};
