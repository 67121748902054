import moment from 'moment';

export const bookingAfterDays = 14;

export const chooseType = (days) => {
  return days.map((day) => {
    if (day.day < moment().add(bookingAfterDays - 1, 'day')) {
      return { ...day, type: 'inactive' };
    }
    if (day.day < moment().add(bookingAfterDays + 7 - 1, 'day')) {
      return { ...day, type: 'closest' };
    }
    return { ...day, type: 'active' };
  });

  /*
  return days.map((day) => {
    if (day.day < moment().add(8, 'day') && day.day > moment().add(1, 'day')) {
      return { ...day, type: 'closest' };
    }
    if (day.day < moment().add(1, 'day')) {
      return { ...day, type: 'inactive' };
    }
    if (day.day > moment().add(8, 'day')) {
      return { ...day, type: 'active' };
    }
    return null;
  });
  */
};

export const notAvailable = (availableSeats, days) => {
  if (availableSeats[0] && availableSeats[0].start) {
    return days.map((day) => {
      const flag = availableSeats.some(
        (seat) =>
          seat.availableSeats &&
          (moment(seat.day).isSame(day.day) ||
            day.day.isSame(moment(availableSeats[0].start).startOf('day'))) &&
          seat.availableSeats.length === 0,
      );
      if (flag) {
        return {
          ...day,
          unavailable: flag,
        };
      } else {
        return day;
      }
    });
  }
  return days.map((day) => {
    const flag = availableSeats.some(
      (seat) =>
        seat.availableSeats &&
        (moment(seat.day).isSame(day.day) ||
          day.day.isSame(moment(availableSeats[0].start).startOf('day'))) &&
        seat.availableSeats.length === 0,
    );
    return {
      ...day,
      unavailable: flag,
    };
  });
};

export const chooseAvailable = (dates, closest) => {
  return dates.map((day) => {
    const all = closest.some(
      (close, id) =>
        day.day.isSame(close.date) &&
        id < closest.length &&
        (close.times[0].availableSeats || []).length === 0 &&
        (close.times[1].availableSeats || []).length === 0,
    );
    const am = closest.some(
      (close, id) =>
        day.day.isSame(close.date) &&
        id < closest.length &&
        (close.times[0].availableSeats || []).length === 0,
    );
    const pm = closest.some(
      (close, id) =>
        day.day.isSame(close.date) &&
        id < closest.length &&
        (close.times[1].availableSeats || []).length === 0,
    );
    if (all) {
      return {
        ...day,
        unavailable: true,
      };
    }
    if (am) {
      return {
        ...day,
        dayPart: 'am',
      };
    }
    if (pm) {
      return {
        ...day,
        dayPart: 'pm',
      };
    }
    return {
      ...day,
    };
  });
};
