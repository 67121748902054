import React from 'react';
import Select from './_common/Select';
import styled from 'styled-components';
import H3 from './_common/H3';
import P from './_common/P';

const DinnerOption = ({
  appWidth,
  title,
  slug,
  values,
  selectDinnerOption,
  options,
  value,
}) => {
  return (
    <DinnerOptions>
      <OptionsLeft>
        {appWidth >= 740 && <EmptyBox />}
        <OptionSection>
          <OptionTitle>{title}</OptionTitle>
          <Select
            value={value.value}
            name={slug}
            options={options}
            changeSelect={selectDinnerOption}
          />
        </OptionSection>
      </OptionsLeft>
      <OptionsRight>
        <QuantityWrapper>
          <PickerWrapper>
            <Select
              value={value.number}
              name={`${slug}_guests`}
              options={values}
              changeSelect={selectDinnerOption}
            />
          </PickerWrapper>
        </QuantityWrapper>
        <PriceWrapper>
          <Price>
            <StyledH3>
              {value.packages[value.value - 1].price_excl_tax * value.number} kr
            </StyledH3>
          </Price>
          {appWidth >= 740 && <EmptyPrice />}
        </PriceWrapper>
      </OptionsRight>
    </DinnerOptions>
  );
};

export default DinnerOption;

const StyledH3 = styled(H3)`
  width: ${(props) => props.theme.width < 740 && '80px'};
`;

const OptionsLeft = styled.div`
  display: flex;
  flex: ${(props) => (props.theme.width >= 740 ? '1' : '4')};
`;

const OptionsRight = styled.div`
  flex: ${(props) => (props.theme.width < 740 ? '0' : '1')};
  display: flex;
  align-items: flex-end;
  overflow: visible;
  justify-content: ${(props) =>
    props.theme.width >= 740 ? 'center' : 'flex-end'};
`;

const DinnerOptions = styled.div`
  margin-top: -10px;
  margin-bottom: ${(props) => (props.theme.width < 740 ? '25px' : '44px')};
  display: flex;
  position: relative;
  &:nth-child(3n) {
    margin-bottom: 44px;
  }
`;

const OptionTitle = styled(P)`
  margin-top: 0 !important;
  margin-bottom: 1px !important;
`;

const QuantityWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.theme.width >= 740 ? 'center' : 'flex-end'};
  flex: 1;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  position: ${(props) => props.theme.width < 740 && 'absolute'};
  top: ${(props) => props.theme.width < 740 && '0'};
  align-self: ${(props) => props.theme.width >= 740 && 'flex-start'};
  margin-top: ${(props) => props.theme.width >= 740 && '26px'};
`;

const PickerWrapper = styled.div`
  width: 40px;
  margin-left: ${(props) => props.theme.width < 740 && '20px'};
`;

const EmptyBox = styled.div`
  width: 40px;
`;

const OptionSection = styled.div`
  flex: 1;
`;

const Price = styled.div`
  flex: 2;
  text-align: right;
`;

const EmptyPrice = styled.div`
  flex: 7;
`;
