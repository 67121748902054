import React from 'react';
import styled from 'styled-components';

const RadioSelect = ({ selectors, selectValue, value }) => (
  <>
    {selectors.map((select) => (
      <StyledLabel key={select.value}>
        {select.title}
        <StyledRadio
          type="radio"
          value={select.value}
          checked={value === select.value}
          onChange={() => selectValue(select.value)}
        />
        <StyledCheckMark selected={value === select.value} />
      </StyledLabel>
    ))}
  </>
);

export default RadioSelect;

const StyledLabel = styled.label`
  font-family: 'minion-pro', sans-serif !important;
  line-height: 20px;
  font-size: 15px !important;
  display: block !important;
  position: relative !important;
  padding-left: 35px !important;
  margin-bottom: 25px !important;
  cursor: pointer !important;
  font-size: ${(props) => props.theme.width < 740 ? 15 : 20}px !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  &:last-child {
    margin-bottom: 0 !important;
  }
`;

const StyledRadio = styled.input`
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
`;

const StyledCheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.black};
  box-sizing: border-box;
  ${(props) =>
    props.selected &&
    `
    &::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background-color: ${props.theme.black}; 
    top: 3px;
    left: 3px;
    border-radius: 50%;
  }
    `}
`;
