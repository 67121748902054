import { notAvailable } from './chooseType';
import _ from 'lodash';
import moment from 'moment';

export const checkDayInfo = (
  res,
  selectedDate,
  setDates,
  dates,
  setAllDates,
  allDates,
  setSelectedDate,
  availableSeats,
  setAvailableSeats,
) => {
  if (selectedDate[0].dayPart === 'am') {
    if (res) {
      setDates(() =>
        dates.map((day) => {
          if (
            res[0].times[0].availableSeats.length === 0 &&
            day.day.isSame(selectedDate[0].date)
          ) {
            if (day.dayPart === 'pm') {
              return { ...day, unavailable: true };
            }
            return { ...day, dayPart: 'am' };
          }
          return day;
        }),
      );
      setAllDates(
        allDates.map((day) => {
          if (
            res[0].times[0].availableSeats.length === 0 &&
            day.day.isSame(selectedDate[0].date)
          ) {
            if (day.dayPart === 'pm') {
              setSelectedDate([{}]);
              return { ...day, unavailable: true };
            }
            setSelectedDate([{}]);
            return { ...day, dayPart: 'am' };
          }
          return day;
        }),
      );
    }
    return [];
  }
  if (selectedDate[0].dayPart === 'pm') {
    if (res) {
      setDates(() =>
        dates.map((day) => {
          if (
            res[0].times[1].availableSeats.length === 0 &&
            day.day.isSame(selectedDate[0].date)
          ) {
            if (day.dayPart === 'am') {
              setSelectedDate([{}]);
              return { ...day, unavailable: true };
            }
            setSelectedDate([{}]);
            return { ...day, dayPart: 'pm' };
          }
          return day;
        }),
      );
      setAllDates(
        allDates.map((day) => {
          if (
            res[0].times[1].availableSeats.length === 0 &&
            day.day.isSame(selectedDate[0].date)
          ) {
            if (day.dayPart === 'am') {
              return { ...day, unavailable: true };
            }
            return { ...day, dayPart: 'pm' };
          }
          return day;
        }),
      );
    }
    return [];
  }
  if (
    allDates.some((day) => selectedDate[0].date.isSame(day.day) && !day.dayPart)
  ) {
    let noAvail = notAvailable([res[1].times[0]], dates);
    setDates(noAvail);
    setAvailableSeats(
      _.uniqBy(
        [
          ...availableSeats,
          ...[res[1].times[0]].reduce(
            (accum, day) =>
              (day.availableSeats || []).length === 0
                ? [
                    ...accum,
                    moment(day.start)
                      .clone()
                      .toDate(),
                  ]
                : accum,
            [],
          ),
        ],
        (el) => JSON.stringify(moment(el).startOf('day')),
      ),
    );
    if (res[1].times[0].availableSeats.length === 0) {
      setSelectedDate([{}]);
    }
    return noAvail;
  }
  return [];
};
