export const blockedDates = [
  [new Date("2022-05-25T00:00:00"), new Date("2022-08-07T23:59:59")],
]

export function excludeBlockedDates(dates) {
  return dates.map( (date) => {
    const {day} = date;
    const dayDate = new Date(day);
    const blocked = blockedDates.find(range => {
      return (dayDate>=range[0] && dayDate<=range[1]);
    })!==undefined;
    if (blocked) {
      return {
        ...date,
        availableSeats: [],
      }
    } else {
      return date;
    }
  })
}
