import React from 'react';
import styled from 'styled-components';
import P from './_common/P';

const Day = ({ date, selectDate, selectedDate, isLoading, width, lang }) => {
  const { type, unavailable, day, dayPart } = date;

  return (
    <Container width={width}>
      {isLoading ? (
        <Date date={day} selectedDate={selectedDate} type={'inactive'}>
          {day.format('D')}
        </Date>
      ) : (
        <>
          {type === 'inactive' && (
            <Date date={day} selectedDate={selectedDate} type={type}>
              {day.format('D')}
            </Date>
          )}
          {type === 'closest' && !unavailable && (
            <>
              {!dayPart && (
                <Circle
                  onClick={() => selectDate(day, 'day')}
                  date={day}
                  selectedDate={selectedDate}>
                  <Date date={day} selectedDate={selectedDate} type={type} dayPart={dayPart}>
                    {day.format('D')}
                  </Date>
                </Circle>
              )}
              {dayPart === 'am' && (
                <Circle
                  onClick={() => selectDate(day, 'pm')}
                  date={day}
                  selectedDate={selectedDate}
                  dayPart={dayPart}>
                  <Date date={day} selectedDate={selectedDate} type={type} dayPart={dayPart}>
                    {day.format('D')}
                  </Date>
                </Circle>
              )}
              {dayPart === 'pm' && (
                <Circle
                  onClick={() => selectDate(day, 'am')}
                  date={day}
                  selectedDate={selectedDate}
                  dayPart={dayPart}>
                  <Date date={day} selectedDate={selectedDate} type={type} dayPart={dayPart}>
                    {day.format('D')}
                  </Date>
                </Circle>
              )}
              <Triangle>
                <DayPart onClick={() => !(dayPart === 'am') && selectDate(day, 'am')}
                         type={type}
                         date={day}
                         selectedDate={selectedDate}
                         dayPart={dayPart}
                         currentDayPart='am'
                >
                  {lang === 'en' ? 'AM' : 'FM'}
                </DayPart>
                <Svg
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  type={type}>
                  {dayPart === 'pm' ? (
                    <>
                      <TriangleWrapper dayPart={dayPart}>
                        <BottomTriangle
                          d="M 0 100 L 100 100 L 100 0 z"
                          date={day}
                          selectedDate={selectedDate}
                          dayPart={dayPart}
                        />
                        <BlackLine d="M 0 100 L 100 -100 L 100 0 z" />
                      </TriangleWrapper>
                    </>
                  ) : (
                    <TriangleWrapper onClick={() => selectDate(day, 'pm')}>
                      <BottomTriangle
                        d="M 0 100 L 100 100 L 100 0 z"
                        date={day}
                        selectedDate={selectedDate}
                        dayPart={dayPart}
                      />
                      { selectedDate.some((date) => day.isSame(date.date)) && (selectedDate[0].dayPart === 'day' || selectedDate[0].dayPart === 'two')
                        ? <WhiteLine d="M 0 100 L 100 -100 L 100 0 z" />
                        : <BlackLine d="M 0 100 L 100 -100 L 100 0 z" />
                      }
                    </TriangleWrapper>
                  )}
                  {dayPart === 'am' ? (
                    <TriangleWrapper>
                      <TopTriangle
                        d="M 99 0 L 0 99 L 0 0 z"
                        date={day}
                        selectedDate={selectedDate}
                        dayPart={dayPart}
                      />
                    </TriangleWrapper>
                  ) : (
                    <TriangleWrapper onClick={() => selectDate(day, 'am')}>
                      <TopTriangle
                        d="M 99 0 L 0 99 L 0 0 z"
                        date={day}
                        selectedDate={selectedDate}
                        dayPart={dayPart}
                      />
                    </TriangleWrapper>
                  )}
                </Svg>
                <DayPart
                  after
                  onClick={() => !(dayPart === 'pm') && selectDate(day, 'pm')}
                  type={type}
                  date={day}
                  selectedDate={selectedDate}
                  dayPart={dayPart}
                  currentDayPart='pm'
                >
                  {lang === 'en' ? 'PM' : 'EM'}
                </DayPart>
              </Triangle>
            </>
          )}
          {type === 'active' && !unavailable && (
            <DayWrapper
              onClick={() => selectDate(day, 'day')}
              date={day}
              selectedDate={selectedDate}>
              <Date date={day} selectedDate={selectedDate} type={type}>
                {day.format('D')}
              </Date>
            </DayWrapper>
          )}
          {unavailable && type !== 'inactive' && (
            <BookedDay date={day} selectedDate={selectedDate}>
              <Date
                date={day}
                selectedDate={selectedDate}
                type={type}
                notAvailable={unavailable}>
                {day.format('D')}
              </Date>
            </BookedDay>
          )}
        </>
      )}
    </Container>
  );
};

export default Day;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: auto;
  height: ${(props) => (props.width > 840 ? '113' : props.width / 7.4)}px;
  border: 1px solid ${(props) => props.theme.black};
  margin: 0 -1px -1px 0;
  background-color: ${(props) => props.marked ? props.theme.black : props.theme.grey};
`;

const Circle = styled.a`
  text-decoration: none;
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => {
    return props.selectedDate.some((date) => props.date.isSame(date.date)) &&
      (props.dayPart === 'pm' ||
        props.dayPart === 'am' ||
        props.selectedDate[0].dayPart === 'day' ||
        props.selectedDate[0].dayPart === 'two')
      ? props.theme.blue
      : props.theme.grey;
  }};
  z-index: 2;
`;

const DayWrapper = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.selectedDate.some((date) => props.date.isSame(date.date)) &&
    props.theme.blue};
`;

const BookedDay = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.inactiveGrey};
`;

const Date = styled(P)`
  color: ${(props) => {
    return (
      props.selectedDate.some((date) => props.date.isSame(date.date)) &&
      (['day', 'two'].includes(props.selectedDate[0].dayPart) || props.dayPart === 'am' || props.dayPart === 'pm')
      ? props.theme.white : props.theme.black
    );
  }};
  color: ${(props) => props.type === 'inactive' && props.theme.textInactiveGray};
  color: ${(props) => props.notAvailable && props.theme.white};
  user-select: none;
`;

const TriangleWrapper = styled.a`
  cursor: ${(props) => (props.dayPart ? 'default' : 'pointer')};
`;

const Triangle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DayPart = styled(P)`
  position: absolute;
  display: ${(props) => (props.type === 'closest' ? 'block' : 'none')};
  left: ${(props) => props.after || 15}%;
  top: ${(props) => props.after || 10}%;
  right: ${(props) => props.after && 15}%;
  bottom: ${(props) => props.after && 10}%;

  color: ${(props) => {
    return (
      props.selectedDate.some((date) => props.date.isSame(date.date)) &&
      (['day', 'two'].includes(props.selectedDate[0].dayPart) || props.selectedDate[0].dayPart === props.currentDayPart) || props.dayPart === props.currentDayPart
      ? props.theme.white : props.theme.black
    );
  }};
  font-size: 10px !important;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
`;

const Svg = styled.svg`
  display: ${(props) => (props.type === 'closest' ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;

const TopTriangle = styled.path`
  fill: ${(props) => {
    return props.selectedDate.some((date) => props.date.isSame(date.date)) &&
      (props.selectedDate[0].dayPart === 'am' ||
        props.selectedDate[0].dayPart === 'day' ||
        props.selectedDate[0].dayPart === 'two')
      ? props.theme.blue
      : props.theme.grey;
  }};
  fill: ${(props) => props.dayPart === 'am' && props.theme.black};
`;

const BottomTriangle = styled.path`
  fill: ${(props) => {
    return props.selectedDate.some((date) => props.date.isSame(date.date)) &&
      (props.selectedDate[0].dayPart === 'pm' ||
        props.selectedDate[0].dayPart === 'day' ||
        props.selectedDate[0].dayPart === 'two')
      ? props.theme.blue
      : props.theme.grey;
  }};
  fill: ${(props) => props.dayPart === 'pm' && props.theme.black};
`;

const BlackLine = styled.path`
  fill: ${(props) => props.theme.black};
`;

const WhiteLine = styled.path`
  fill: ${(props) => props.theme.white};
`;
