import { getRoomPriceByPart } from './roomPrice';

export const changeTotal = (
  roomPrice,
  addons,
  addonsPrices,
  dinnerPackages,
  guests,
  dayPart,
  setTotal,
) => {
  const guidedTourVAT = addons.guided && addonsPrices.guided.price;
  const fullDayVAT = addons.full_day && addonsPrices.full_day.price * guests;
  const dinnerPrice = (field) => {
    if (addons.dinner) {
      let dinner = 0;
      for (let [key, value] of Object.entries(dinnerPackages)) {
        if (key === 'alco') {
          dinner =
            dinner + value.packages[value.value - 1][field] * value.number;
        } else {
          dinner =
            dinner + value.packages[value.value - 1][field] * value.number;
        }
      }
      return dinner;
    }
    return 0;
  };
  //
  // const dinnerPrice = () => {
  //   if (addons.dinner) {
  //     let dinnerWithoutVAT = 0;
  //     for (let [key, value] of Object.entries(dinnerPackages)) {
  //       if (key === 'alco') {
  //         dinnerWithoutVAT =
  //           dinnerWithoutVAT +
  //           value.packages[value.value - 1].price_excl_tax * value.number;
  //       } else {
  //         dinnerWithoutVAT =
  //           dinnerWithoutVAT +
  //           value.packages[value.value - 1].price_excl_tax * value.number;
  //       }
  //     }
  //     return dinnerWithoutVAT;
  //   }
  //   return 0;
  // };

  const roomPriceForPart = getRoomPriceByPart(roomPrice, dayPart);
  setTotal({
    withVAT:
      dayPart === 'two'
        ? (
          roomPriceForPart.priceWithVAT +
          fullDayVAT * 2 +
          dinnerPrice('price') +
          guidedTourVAT
        ).toFixed(2)
        :
        (
          roomPriceForPart.priceWithVAT +
          guidedTourVAT +
          fullDayVAT +
          dinnerPrice('price')
        ).toFixed(2),
    withoutVAT:
      dayPart === 'two'
        ? (addons.guided && addonsPrices.guided.price_excl_tax) +
        roomPriceForPart.price +
        (addons.full_day &&
          addonsPrices.full_day.price_excl_tax * guests) * 2 +
        dinnerPrice('price_excl_tax')
        : roomPriceForPart.price +
        (addons.guided && addonsPrices.guided.price_excl_tax) +
        (addons.full_day && addonsPrices.full_day.price_excl_tax * guests) +
        dinnerPrice('price_excl_tax')
  });
};
