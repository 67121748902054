export const getRoomPriceByPart = (roomPrice, dayPart) => {
  const mapper = {
    'two': 'two_days',
    'two_halfs': 'two_halfs',
    'am': 'am',
    'pm': 'pm',
    'day': 'day',
  }
  if (dayPart in mapper && mapper[dayPart] in roomPrice) {
    return roomPrice[mapper[dayPart]];
  } else {
    return {
      id: null,
      price: null,
      priceWithVAT: null,
    };
  }
};