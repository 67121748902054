import _ from 'lodash';
import { getRoomPriceByPart } from './roomPrice';

const countQuantity = (name, dayPart, guests) => {
  if (name === 'dinner') {
    return [];
  } else if (name === 'full_day') {
    return dayPart === 'two' ? guests * 2 : guests;
  } else {
    return 1;
  }
};

export const generateLineItems = (
  selectedAddons,
  addonsValues,
  dinnerPackages,
  dayPart,
  guests,
  roomPrice,
) => {
  if (dinnerPackages) {
    let array = [];
    _.forOwn(selectedAddons, (value, key) => {
      if (value === true && key !== 'dinner' && key !== 'allergies') {
        array.push({
          quantity: +countQuantity(
            key,
            dayPart,
            addonsValues[key].value || guests,
          ),
          product_id: +addonsValues[key].id,
          product_price: +addonsValues[key].price,
        });
      } else if (value === true && key === 'dinner') {
        _.forOwn(dinnerPackages, (value) => {
          if (value.number > 0) {
            array.push({
              quantity: +value.number,
              product_id: +value.packages[value.value - 1].id,
              product_price: +value.packages[value.value - 1].price,
            });
          }
        });
      }
    });

    const roomPriceForPart = getRoomPriceByPart(roomPrice, dayPart);
    array.push({
      // quantity: dayPart === 'two' ? 2 : 1,
      quantity: 1,
      product_id: roomPriceForPart.id,
      product_price: roomPriceForPart.priceWithVAT,
    });

    return array;
  }
};
