export const theme = {
  mainFont: "'minion-pro', sans-serif",
  secondaryFont: "'apotek-comp', serif",
  lineBlack: "#202428",
  lightGray: "#E9ECEE",
  grey: "#F5F2F0",
  inactiveGrey: "#5A5A5A",
  textInactiveGray: "#adb5bd",
  blue: "#3637E6",
  orange: "#EE5935",
  error: "#F22613",
  black: "#212529",
  white: "#fff",
};
