import styled from 'styled-components';

const H3 = styled.h3`
  font-family: 'minion-pro', sans-serif !important;
  font-weight: normal !important;
  font-size: ${(props) => props.theme.width >= 740 ? '20px' : '15px'} !important;
  line-height: 20px !important;
  color: ${(props) => props.theme.black} !important;
  margin: 0px;
`;

export default H3;
