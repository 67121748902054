import React, { useEffect } from 'react';
import Container from '../components/Container';
import SectionContainer from '../components/SectionContainer';
import ButtonsRow from '../components/ButtonsRow';
import ScheduleOption from '../components/ScheduleOption';
import { generateHours } from '../helpers/generateHours';
import AbsoluteContainer from '../components/AbsoluteContainer';
import { scheduleOpt } from '../helpers/scheduleOptions';
import styled from 'styled-components';
import { generateGuided } from '../helpers/generateGuided';

const Schedule = ({
  appWidth,
  closeApp,
  selectedDate,
  setCurrPage,
  twoDaysPage,
  twoDaysSchedule,
  addons,
  schedule,
  changeSchedule,
  lang,
  guidedTourTime,
  addonsPrices,
}) => {
  const firstFikaHours = () => {
    if (twoDaysPage === 1) {
      return generateHours(
        +schedule[0].arrival.split(':')[0],
        +schedule[0].lunch.split(':')[0],
        schedule[0].arrival.split(':')[1],
        schedule[0].lunch.split(':')[1],
      );
    } else {
      return generateHours(
        +schedule[1].arrival.split(':')[0],
        +schedule[1].lunch.split(':')[0],
        schedule[1].arrival.split(':')[1],
        schedule[1].lunch.split(':')[1],
      );
    }
  };

  const secondFikaHours = () => {
    if (twoDaysPage === 1) {
      return generateHours(
        +schedule[0].lunch.split(':')[0],
        +schedule[0].ends.split(':')[0],
        schedule[0].lunch.split(':')[1],
        schedule[0].ends.split(':')[1],
      );
    } else {
      return generateHours(
        +schedule[1].lunch.split(':')[0],
        +schedule[1].ends.split(':')[0],
        schedule[1].lunch.split(':')[1],
        schedule[1].ends.split(':')[1],
      );
    }
  };

  const generateLunchHours = () => {
    if (selectedDate[0].dayPart === 'am') {
      return generateHours(
        +schedule[0].fika.split(':')[0],
        +schedule[0].ends.split(':')[0],
        schedule[0].fika.split(':')[1],
        schedule[0].ends.split(':')[1],
      );
    } else if (selectedDate[0].dayPart === 'pm') {
      return generateHours(
        +schedule[0].arrival.split(':')[0],
        +schedule[0].fika2.split(':')[0],
        schedule[0].arrival.split(':')[1],
        schedule[0].fika2.split(':')[1],
      );
    } else {
      return generateHours(
        +schedule[twoDaysPage - 1].fika.split(':')[0],
        +schedule[twoDaysPage - 1].fika2.split(':')[0],
        schedule[twoDaysPage - 1].fika.split(':')[1],
        schedule[twoDaysPage - 1].fika2.split(':')[1],
      );
    }
  };

  const options = (feature) => {
    const featureSlug = feature
      .split(' ')
      .join('_')
      .toLowerCase();
    let options = [];
    switch (selectedDate[0].dayPart) {
      case 'am':
        switch (featureSlug) {
          case 'arrival':
            options = generateHours(9, 10);
            break;
          case 'fika':
            options = firstFikaHours();
            break;
          case 'lunch':
            options = generateLunchHours();
            break;
          case 'ends':
            options = generateHours(13, 13);
            break;
          default:
            options = generateHours(9, 13);
            break;
        }
        break;
      case 'pm':
        switch (featureSlug) {
          case 'arrival':
            options = generateHours(13, 13);
            break;
          case 'lunch':
            options = generateLunchHours();
            break;
          case 'fika2':
            options = secondFikaHours();
            break;
          case 'ends':
            options = generateHours(16, 18);
            break;
          default:
            options = generateHours(13, 18);
            break;
        }
        break;
      default:
        switch (featureSlug) {
          case 'arrival':
            options = generateHours(9, 10);
            break;
          case 'fika':
            options = firstFikaHours();
            break;
          case 'lunch':
            options = generateLunchHours();
            break;
          case 'fika2':
            options = secondFikaHours();
            break;
          case 'ends':
            options = generateHours(16, 18);
            break;
          default:
            options = generateHours(9, 18);
            break;
        }
        break;
    }
    return options;
  };

  const generateDay = () => {
    if (selectedDate[0].dayPart === 'two') {
      return `${lang === 'en' ? 'Day ' : 'Dag'} ${twoDaysPage}: ${
        twoDaysPage === 1
          ? selectedDate[0].date
              .locale(lang === 'en' ? 'en-gb' : 'sv')
              .format('DD MMMM YYYY')
          : selectedDate[1].date
              .locale(lang === 'en' ? 'en-gb' : 'sv')
              .format('DD MMMM YYYY')
      }`;
    } else {
      return `${
        twoDaysPage === 1
          ? selectedDate[0].date
              .locale(lang === 'en' ? 'en-gb' : 'sv')
              .format('DD MMMM YYYY')
          : selectedDate[1].date
              .locale(lang === 'en' ? 'en-gb' : 'sv')
              .format('DD MMMM YYYY')
      }`;
    }
  };

  useEffect(() => {
    if (selectedDate[0].dayPart === 'am') {
      changeSchedule(
        { target: { name: 'Guided tour', value: 'first_after' } },
        1,
      );
    }
  }, []);

  return (
    <AbsoluteContainer>
      <Container
        title={lang === 'en' ? 'Schedule' : 'Schema'}
        closeApp={closeApp}>
        <StyledSectionContainer title={generateDay()} schedule>
          {scheduleOpt(selectedDate, lang).map((option) => {
            return (
              <ScheduleOption
                key={option.slug}
                title={option.title}
                slug={option.slug}
                text={option.text}
                value={
                  twoDaysPage === 1
                    ? schedule[0][option.slug.toLowerCase()]
                    : schedule[1][option.slug.toLowerCase()]
                }
                options={options(option.slug)}
                changeSchedule={changeSchedule}
                day={twoDaysPage}
                appWidth={appWidth}
                lang={lang}
                addons={addons}
              />
            );
          })}
          {addons.guided && twoDaysPage === 1 && (
            <ScheduleOption
              title={addonsPrices.guided.name}
              slug={'guided tour'}
              text={
                lang === 'en'
                  ? 'Exact time set by Fotografiska'
                  : 'Exakt tid bestäms av Fotografiska'
              }
              options={generateGuided(lang, selectedDate[0].dayPart)}
              changeSchedule={changeSchedule}
              day={twoDaysPage}
              value={guidedTourTime}
              appWidth={appWidth}
              dayPart={selectedDate[0].dayPart}
              lang={lang}
              addons={addons}
            />
          )}
        </StyledSectionContainer>
        <SectionContainer>
          <ButtonsRow
            back="venue"
            next="summary"
            funcBack={
              selectedDate.length > 1 && twoDaysPage === 2
                ? () => twoDaysSchedule('back')
                : setCurrPage
            }
            funcNext={
              selectedDate.length > 1 && twoDaysPage === 1
                ? () => twoDaysSchedule('next')
                : setCurrPage
            }
            lang={lang}
          />
        </SectionContainer>
      </Container>
    </AbsoluteContainer>
  );
};

export default Schedule;

const StyledSectionContainer = styled(SectionContainer)`
  margin-bottom: -25px !important;
`;
