import React from 'react';
import styled from 'styled-components';

const Select = ({ name, options, changeSelect, value, availability, day, height='40px', selectOption }) => {
  return (
    <SelectorWrapper>
      {availability && (
        <Selector
          height={height}
          value={value}
          name={name}
          id={name}
          onChange={(e) => {
            changeSelect(e, day);
          }}>
          {options.map((option) => {
            return (
              <Option
                key={option.name + option.value + name}
                value={`${option.value}${
                  option.price_excl_tax ? ` ${option.price_excl_tax}` : ''
                }`}
                disabled={!availability.some((el) => el === option.value)}>
                {option.name}
              </Option>
            );
          })}
        </Selector>
      )}
      {!availability && (
        <Selector
          height={height}
          value={value}
          name={name}
          id={name}
          onChange={(e) => {
            changeSelect(e, day);
          }}>
          {selectOption && <option value="" selected disabled hidden>{ selectOption }</option>}
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {`${option.name}${
                option.price_excl_tax ? ` ${option.price_excl_tax}kr` : ''
              }`}
            </Option>
          ))}
        </Selector>
      )}
    </SelectorWrapper>
  );
};

export default Select;

const SelectorWrapper = styled.div`
  background-position: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAADxJREFUCB1jZGBgcANiFyDGB/YwA2XvAjEjECvhULkHKL4LpBAEcCkGKwIpgCnEphiuCCSJDYDcDMIoAADQ1gk/0N41RwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
`;

const Selector = styled.select`
  font-family: 'minion-pro', sans-serif !important;
  font-size: ${(props) => props.theme.width < 740 ? 15 : 20}px !important;
  color: ${(props) => props.theme.black} !important;
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  outline: 0 !important;
  width: 100% !important;
  height: ${(props) => props.height} !important;
  margin: 0px !important;
  overflow: hidden !important;
  border-bottom: 1px solid #212529 !important;
  -webkit-appearance: none !important;
  padding-left: 0 !important;
  padding-right: 20px;
`;

const Option = styled.option`
  font-family: 'minion-pro', sans-serif !important;
  font-size: 15px !important;
  text-transform: capitalize;
`;
