import styled from 'styled-components';

const H1 = styled.h1`
  font-family: ${(props) => props.theme.secondaryFont} !important;
  font-size: ${(props) =>
    props.theme.width >= 740 ? '40px' : '30px'} !important;
  font-weight: normal;
  color: ${(props) => props.theme.black} !important;
  line-height: 45px;
`;

export default H1;
