import React from 'react';
import H2 from './_common/H2';
import styled from 'styled-components';
import { scheduleOpt } from '../helpers/scheduleOptions';
import H3 from './_common/H3';
import { generateGuided } from '../helpers/generateGuided';

const ScheduleBlock = ({
  day,
  schedule,
  selectedDay,
  addons,
  selectedDate,
  lang,
  guidedTourTime,
  addonsPrices,
  appWidth,
}) => {
  const GuidedTour = () =>
    appWidth >= 740 ? (
      <Option>
        <LeftWrapper isGuided={addons.guided} name="guided">
          <Title en={lang === 'en'}>{addonsPrices.guided.name}</Title>
          <Time>
            {
              generateGuided(lang, selectedDate[0].dayPart).filter(
                (option) => option.value === guidedTourTime,
              )[0].name
            }
          </Time>
        </LeftWrapper>
        <Caption guided isGuided={addons.guided}>
          {lang === 'en'
            ? 'Exact time set by Fotografiska'
            : 'Exakt tid bestäms av Fotografiska'}
        </Caption>
      </Option>
    ) : (
      <Option>
        <LeftWrapper isGuided={addons.guided} name="guided">
          <Title en={lang === 'en'}>{addonsPrices.guided.name}</Title>
        </LeftWrapper>
        <RightWrapper guided>
          <Time>
            {
              generateGuided(lang, selectedDate[0].dayPart).filter(
                (option) => option.value === guidedTourTime,
              )[0].name
            }
          </Time>
          <Caption guided isGuided={addons.guided}>
            {lang === 'en'
              ? 'Exact time set by Fotografiska'
              : 'Exakt tid bestäms av Fotografiska'}
          </Caption>
        </RightWrapper>
      </Option>
    );

  return (
    <Wrapper>
      <StyledH2>
        {day.dayPart === 'two' &&
          `${lang === 'en' ? 'Day' : 'Dag'} ${selectedDay + 1}: `}
        {day.date.format('DD MMMM YYYY')}
      </StyledH2>
      <ContentWrapper>
        {scheduleOpt(selectedDate, lang).map((option) => (
          <Option key={option.slug}>
            <LeftWrapper isGuided={addons.guided} name={option.slug}>
              <Title en={lang === 'en'}>
                {option.slug === 'fika' &&
                schedule[selectedDay][option.title.toLowerCase()].split(
                  ':',
                )[0] <= 9
                  ? lang === 'en'
                    ? 'Breakfast'
                    : 'Frukost'
                  : option.title}
              </Title>
              <Time>
                {+schedule[selectedDay][option.slug.toLowerCase()].split(
                  ':',
                )[0] < 10
                  ? `0${schedule[selectedDay][option.slug.toLowerCase()]}`
                  : schedule[selectedDay][option.slug.toLowerCase()]}
              </Time>
            </LeftWrapper>
            <Caption isGuided={addons.guided}>
              {option.slug === 'fika' &&
              schedule[selectedDay][option.title.toLowerCase()].split(':')[0] <=
                9
                ? lang === 'en'
                  ? 'Served in the restaurant'
                  : 'Serveras i restaurangen'
                : option.text}
            </Caption>
          </Option>
        ))}
        {addons.guided &&
          selectedDay === 1 &&
          generateGuided(lang, selectedDate[0].dayPart)
            .map((e) => {
              return e.value;
            })
            .indexOf(guidedTourTime) > 1 && <GuidedTour />}
        {addons.guided &&
          selectedDay === 0 &&
          generateGuided(lang, selectedDate[0].dayPart)
            .map(function(e) {
              return e.value;
            })
            .indexOf(guidedTourTime) < 2 && <GuidedTour />}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ScheduleBlock;

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.width < 740 ? '-35px': '-10px'};
  &:not(:last-of-type) {
    margin-bottom: -2px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.width < 740 && '40px'};
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${(props) => props.theme.width < 740 && 'center'};
  align-items: ${(props) => props.theme.width < 740 && 'baseline'};
  margin-bottom: ${(props) => (props.theme.width >= 740 ? '5px' : '0')};
`;

const Title = styled(H3)`
  margin: 0 !important;
  flex: ${(props) => (props.theme.width < 740 ? '13' : (props.en ? '11' : '12'))};
`;

const Time = styled(H3)`
  flex: 10;
`;

const Caption = styled(H3)`
  flex: ${(props) => props.isGuided ? 4 : 5};
  margin-bottom: 20px;
  padding-left: ${(props) =>
    props.theme.width < 740 && !props.guided && '10px'};
`;

const LeftWrapper = styled.div`
  flex: ${(props) => props.isGuided ? 3 : 2};
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.theme.width < 740 && props.name ? '40px' : '20px' };
  margin-top: ${(props) => props.theme.width < 740 && !props.name && '40px'};
`;

const RightWrapper = styled.div`
  display: flex;
  flex: ${(props) => props.guided ? '4' : '3'};
  flex-direction: column;
  padding-left: ${(props) => props.theme.width < 740 && '10px'};
`;

const StyledH2 = styled(H2)`
  font-size: ${(props) => props.theme.width < 740 ? '15px' : '20px'} !important;
  font-family: ${(props) => props.theme.mainFont} !important;
  font-weight: normal !important;
  margin-bottom: 22px !important;
`;
