import React from 'react';
import styled from 'styled-components';
import H1 from './_common/H1';

const Container = ({ children, title, closeApp }) => (
  <Wrapper>
    <Cross onClick={closeApp} />
    <StyledH1>{title}</StyledH1>
    {children}
  </Wrapper>
);

export default Container;

const Wrapper = styled.div`
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 8px 8px 0 rgba(0, 0, 0, 0.1),
    0 16px 16px 0 rgba(0, 0, 0, 0.1), 0 32px 32px 0 rgba(0, 0, 0, 0.1),
    0 64px 64px 0 rgba(0, 0, 0, 0.1);
  padding: 45px 80px 70px;
  max-width: 1120px;
  margin: 40px auto;
  box-sizing: border-box;
  text-align: center;
  background-color: ${(props) => props.theme.grey};
  @media (max-width: 1150px) {
    padding: 25px 20px 40px 20px;
    margin: 40px 15px;
  }
`;

const Cross = styled.div`
  position: absolute;
  cursor: pointer;
  right: ${(props) => (props.theme.width >= 740 ? '20px' : '0px')};
  top: ${(props) => (props.theme.width >= 740 ? '20px' : '-28px')};
  width: 20px;
  height: 20px;
  &:before,
  &:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${(props) => props.theme.black};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const StyledH1 = styled(H1)`
  margin-bottom: ${(props) => props.theme.width < 1150 ? 28 : 50}px !important;
  margin-top: 0;
  font-size: ${(props) => props.theme.width < 740 && '24px'} !important;
  line-height: ${(props) => props.theme.width < 740 && 'normal'};
`;
