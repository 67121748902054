import React from 'react';
import Checkbox from './_common/Checkbox';
import P from './_common/P';
import styled from 'styled-components';
import H3 from './_common/H3';
import Input from './_common/Input';

const OptionRow = ({
  appWidth,
  change,
  value,
  guests,
  price,
  name,
  caption,
  total,
  checkBoxName,
  changeAllergies,
  allergies,
  pack,
  allergiesError,
  lang,
  bigText,
  hide,
}) => {
  if (hide) return null

  return (
    <Wrapper allergies={checkBoxName}>
      <OptionWrapper>
        <Option>
          {checkBoxName && (
            <CheckboxWrap>
              <Checkbox value={value} change={change} name={checkBoxName} />
            </CheckboxWrap>
          )}
          <NameBlock>
            <NameRow>
              <Name>{name}</Name>
              {price && appWidth >= 740 && <H3>{price} kr</H3>}
            </NameRow>
            {bigText ? <StyledH3>{caption}</StyledH3> : <StyledP>{caption}</StyledP>}
          </NameBlock>
        </Option>
        {checkBoxName === 'allergies' && value && (
          <InputWrapper>
            {appWidth >= 740 && <EmptyAllergy />}
            <InputBlock>
              <Input
                title={
                  lang === 'en' ? 'Please specify' : 'Vänligen specificera'
                }
                onChange={(e) => changeAllergies(e)}
                value={allergies}
                error={
                  allergiesError && { message: 'Wrong format of allergies' }
                }
              />
            </InputBlock>
          </InputWrapper>
        )}
      </OptionWrapper>
      <OptionValues pack={pack}>
        {appWidth >= 740 && (
          <People>
            <H3>{guests}</H3>
          </People>
        )}
        <AddonPrice>
          <Price>{total && <H3>{total} kr</H3>}</Price>
          {appWidth >= 740 && <EmptyPrice />}
        </AddonPrice>
      </OptionValues>
    </Wrapper>
  );
};

export default OptionRow;

const CheckboxWrap = styled.div`
  margin-right: 20px;
  line-height: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${(props) => (props.allergies === 'allergies' ? '0' : '40px')};
  align-items: flex-start;
`;

const Option = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
`;

const OptionValues = styled.div`
  display: flex;
  flex: 1;
  position: ${(props) => props.theme.width < 740 && 'absolute'};
  right: ${(props) => props.theme.width < 740 && '0'};
  align-self: ${(props) => props.pack && props.width < 740 && 'flex-end'};
`;

const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const Name = styled(H3)`
  margin: 0;
  margin-right: 10px !important;
  padding-right: ${(props) => props.theme.width < 740 && '65px'};
`;

const People = styled.div`
  display: flex;
  flex: 2;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
`;

const AddonPrice = styled.div`
  display: flex;
  flex: 7;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: -5px;
  margin-bottom: 5px;
`;

const Price = styled.div`
  flex: 1;
  text-align: right;
`;

const EmptyPrice = styled.div`
  flex: 3;
`;

const EmptyAllergy = styled.div`
  width: 40px;
`;

const InputBlock = styled.div`
  flex: 1;
`;

const StyledP = styled(P)`
  padding-right: 75px;
`;

const StyledH3 = styled(H3)`
  padding-right: 75px;
`;
