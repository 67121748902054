import styled from 'styled-components';

const H2 = styled.h2`
  font-family: ${(props) => props.theme.secondaryFont} !important;
  font-weight: normal !important;
  font-size: ${(props) => (props.theme.width < 740 ? '20px' : '25px')} !important;
  line-height: 25px !important;
  color: ${(props) => props.theme.black} !important;
  text-transform: capitalize;
  margin: 0px;
`;

export default H2;
