import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  createBooking,
  getAvailableSeats,
  getDayInfo,
  getInfo,
  getVenueInfo,
} from '../helpers/http';
import {
  bookingAfterDays,
  chooseAvailable,
  chooseType,
  notAvailable,
} from '../helpers/chooseType';
import { makeCalendar } from '../helpers/makeCalendar';
import { dayPartPackage } from '../helpers/selectDayPartPackage';
import { generateLineItems } from '../helpers/generateLineItems';
import { useStripe } from '@stripe/react-stripe-js';
import Calendar from '../pages/Calendar';
import { amSchedule, pmSchedule, fullSchedule } from './ScheduleContainer';
import { generateDefaultPackages } from '../helpers/defaultPackages';
import { changeTotal } from '../helpers/computeTotal';
import { checkDayInfo } from '../helpers/checkDayInfo';
import { checkAvailability } from '../helpers/checkDayParts';

const CalendarContainer = (props) => {
  const {
    setSchedule,
    schedule,
    guidedTourTime,
    setGuidedTourTime,
    changeSchedule,
    rooms,
    slug,
    maxGuests,
  } = props;
  const stripe = useStripe();

  //STATE

  const [selectedRoomSlug, setSelectedRoomSlug] = useState(slug || 'Sarah');
  let selectedRoom = rooms.find(room => room.slug === selectedRoomSlug) || rooms[0]
  if (maxGuests) selectedRoom.maxGuests = maxGuests

  const [id, setId] = useState();

  const [availableSeats, setAvailableSeats] = useState([]);

  const [selectedDate, setSelectedDate] = useState([]);
  const [roomNotAvailable, setRoomNotAvailable] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const datepickerStartDate = moment().add(bookingAfterDays, 'days').add(selectedMonth, 'month').startOf('month').toDate();

  const [currMonth, setCurrMonth] = useState(moment());

  const [days, setDays] = useState([]);
  const [closestDays, setClosestDays] = useState([]);

  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [datepickerDates, setDatepickerDates] = useState([
    { day: moment().startOf('month') },
    { day: moment().endOf('month') },
  ]);
  const [datepickerDays, setDatePickerDays] = useState([]);
  const [datepickerLoading, setDatepickerLoading] = useState(false);

  const [allDates, setAllDates] = useState([]);

  const [dayParts, setDayParts] = useState(['two', 'day', 'am', 'pm']);
  const [dayPart, setDayPart] = useState('day');
  const [guests, setGuests] = useState('1');

  const [fetching, setFetching] = useState(false);

  //Action handlers

  const [error, setError] = useState();
  const [isBlocked, setIsBlocked] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isPaying, setIsPaying] = useState(false);

  const [currPage, setCurrPage] = useState('calendar');

  const [saveDetails, setSaveDetails] = useState(false);

  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    company_org_number: '',
  });

  const [addons, setAddons] = useState({
    full_day: false,
    guided: false,
    dinner: false,
    allergies: false,
  });

  const [twoDaysPage, setTwoDaysPage] = useState(1);

  const [addonsPrices, setAddonsPrices] = useState({
    full_day: {
      price: 567,
      id: 1,
    },
    guided: {
      price: 2406,
      id: 1,
    },
  });

  const [allergies, setAllergies] = useState('');

  const [roomPrice, setRoomPrice] = useState({});

  const [total, setTotal] = useState({
    withoutVAT: 0,
    withVAT: 0,
  });

  const [addonsPackages, setAddonsPackages] = useState();

  const [dinnerPackages, setDinnerPackages] = useState(
    generateDefaultPackages(addonsPackages, dayPart, guests),
  );

  //METHODS

  const closeApp = () => {
    fetchDays();
    setCurrPage('calendar');
    setTwoDaysPage(1);
    setSelectedDate([{}]);
    setAddons({
      full_day: false,
      guided: false,
      dinner: false,
      allergies: false,
    });
    setGuidedTourTime('first_before');
    setSchedule(fullSchedule);
    setAllergies('');
    setIsPaid(false);
    setSaveDetails(false);
  };

  const twoDaysSchedule = (direction) => {
    if (direction === 'next') {
      return setTwoDaysPage(2);
    }
    setTwoDaysPage(1);
  };

  const changeAllergies = (e) => {
    setAllergies(e.target.value);
    if (e.target.value === '') {
      return setAddons({ ...addons, allergies: false });
    }
    setAddons({ ...addons, allergies: true });
  };

  const changeDetails = (flag) => {
    if (flag === 'flag') {
      return setSaveDetails(false);
    }
    setSaveDetails(!saveDetails);
  };

  const selectDinnerOption = (e) => {
    if (e.target.name.split('_')[1]) {
      return setDinnerPackages(
        dinnerPackages.map((el) =>
          e.target.name.split('_')[0] === el.slug
            ? { ...el, number: +e.target.value }
            : el,
        ),
      );
    }
    return setDinnerPackages(
      dinnerPackages.map((el) =>
        e.target.name.split('_')[0] === el.slug
          ? { ...el, value: +e.target.value }
          : el,
      ),
    );
  };

  const changeAddons = (event) => {
    const { name } = event.target;
    setAddons({ ...addons, [name]: !addons[name] });
    if (name === 'allergies' && addons[name] === true) {
      setAllergies('');
    }
  };

  const createVenueBooking = (userInfo, payment_info) => {
    // if (payment_info.sources) {
    //   setIsPaying(true);
    // }
    setIsPaying(true);
    setIsBlocked(true);
    setError();
    let data = {
      ...userInfo,
      venue_id: selectedRoom.roomId,
      guests: +guests,
      finalize: true,
      booking_dates: [
        {
          booking_date: selectedDate[0].date
            .clone()
            .startOf('day')
            .utcOffset(0, true)
            .format(),
          schedule: {
            ...schedule[0],
          },
          duration:
            selectedDate[0].dayPart === 'two' ? 'day' : selectedDate[0].dayPart,
        },
        selectedDate.length > 1
          ? {
              booking_date: selectedDate[1].date
                .clone()
                .startOf('day')
                .utcOffset(0, true)
                .format(),
              schedule: {
                ...schedule[1],
              },
              duration: 'day',
            }
          : null,
      ],
      cost: +total.withVAT,
      allergies,
      purchase: {
        currency: 'SEK',
        line_items: generateLineItems(
          addons,
          addonsPrices,
          dinnerPackages,
          dayPart,
          guests,
          roomPrice,
        ),
      },
    };

    if (addons.guided) {
      data = {
        ...data,
        guided_tour_day:
          guidedTourTime.split('_')[0] === 'first' ? 'Day 1' : 'Day 2',
        guided_tour_time:
          guidedTourTime.split('_')[1] === 'before'
            ? 'Before lunch'
            : 'After lunch',
      };
    }

    if (payment_info.sources) {
      data = {
        ...data,
        payment_info,
      };
    }

    getDayInfo(selectedDate[0].date.format('YYYY-MM-DD'), id)
      .then((res) => {
        if (selectedDate[0].dayPart === 'am') {
          data = {
            ...data,
            booking_dates: [
              {
                ...data.booking_dates[0],
                booking_start_time: res[0].times[0].start,
                caspeco_rule_id: res[0].id,
              },
            ],
          };
        } else if (selectedDate[0].dayPart === 'pm') {
          data = {
            ...data,
            booking_dates: [
              {
                ...data.booking_dates[0],
                booking_start_time: res[0].times[1].start,
                caspeco_rule_id: res[0].id,
              },
            ],
          };
        } else {
          data = {
            ...data,
            booking_dates: [
              {
                ...data.booking_dates[0],
                booking_start_time: res[1].times[0].start,
                caspeco_rule_id: res[1].id,
              },
              data.booking_dates[1] ? { ...data.booking_dates[1] } : null,
            ],
          };
        }

        if (selectedDate.length > 1) {
          return getDayInfo(selectedDate[1].date.format('YYYY-MM-DD'), id).then(
            (res) => {
              data = {
                ...data,
                booking_dates: [
                  data.booking_dates[0],
                  {
                    ...data.booking_dates[1],
                    booking_start_time: res[1].times[0].start,
                    caspeco_rule_id: res[1].id,
                  },
                ],
              };
            },
          );
        }
        data = { ...data, booking_dates: [data.booking_dates[0]] };
      })
      .then(() => createBooking(data, props.lang))
      .then(() => {
        if (!payment_info.sources) {
          fetchDays();
          setUserInfo(userInfo);
          setCurrPage('confirmation');
          setIsBlocked(false);
          setIsPaying(false);
        } else {
          fetchDays();
          setUserInfo(userInfo);
          setIsBlocked(false);
          setIsPaid(true);
          setIsPaying(false);
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.status &&
          e.response.data.requires_action
        ) {
          return handleAction(
            e.response.data.data.client_secret,
            userInfo,
            payment_info.id,
          );
        }
        setIsPaying(false);
        setError(
          `${(e.response && e.response.data.errors && e.response.data.errors.detail) ||
            (e.response ? e.response.data : "unknown error occurred")}`,
        );
        setIsBlocked(false);
      });
  };

  const handleAction = (clientSecret, userInfo) => {
    stripe
      .handleCardAction(clientSecret)
      .then((result) => {
        if (result.error) {
          setError(`${result.error.message}`);
          setIsPaying(false);
          setIsBlocked(false);
        } else {
          const payment_info = {
            sources: [
              {
                type: 'stripe',
                amount: +total.withVAT,
                payment_intent_id: result.paymentIntent.id,
              },
            ],
          };

          createVenueBooking(userInfo, payment_info);
        }
      })
      .catch((err) => {
        setIsPaying(false);
        setIsBlocked(false);
        setError(`${err.response.data}`);
      });
  };

  const extractVenueBookingPricing = (bookingProducts) => {
    return bookingProducts.reduce((acc, bookingProduct) => {
      acc[bookingProduct.booking_period] = {
        id: bookingProduct.product.id,
        price: bookingProduct.product.price_excl_tax,
        priceWithVAT: bookingProduct.product.price,
      }
      return acc;
    }, {})
  }

  const fetchDays = () => {
    if (!selectedRoomSlug) return []

    setIsLoading(true);
    if (days.length > 0 && selectedMonth === 0) {
      let dates = [];
      for (
        let thisDay = moment()
          .clone()
          .startOf('day')
          .add(bookingAfterDays, 'day');
        thisDay <
        moment()
          .clone()
          .startOf('day')
          .add(9, 'days');
        thisDay = thisDay.clone().add(1, 'day')
      ) {
        if (thisDay.clone().weekday() !== 5 && thisDay.weekday() !== 6) {
          dates = [...dates, thisDay];
        }
      }

      getVenueInfo(selectedRoom.roomId, props.lang)
        .then((res) => {
          if (currPage !== 'payment') {
            setRoomPrice(extractVenueBookingPricing(res.data.data.booking_products));

            /*setRoomPrice({
              price: res.data.data.booking_product.price_excl_tax,
              priceWithVAT: res.data.data.booking_product.price,
              id: res.data.data.booking_product.id,
            });*/
            setAddonsPackages(res.data.data.addons);
          }
          setId(res.data.data.caspeco_section_id);
          return Promise.all([
            ...dates.map((day) =>
              getInfo(
                day.format('YYYY-MM-DD'),
                res.data.data.caspeco_section_id,
              ),
            ),
            getAvailableSeats(days, res.data.data.caspeco_section_id),
          ])
            .then((res) => {
              if (res.length <= 0) {
                return Promise.reject();
              }
              setDates(createDatesStates(res[res.length - 1].data, res, days));
              setClosestDays(res);
              setIsLoading(false);
            })
            .catch((e) => {
              console.warn(e);
              setIsLoading(false);
              setRoomNotAvailable(true);
            });
        })
        .catch((e) => {
          console.warn(e);
          setIsLoading(false);
          setRoomNotAvailable(true);
        });
    } else if (days.length > 0) {
      getAvailableSeats(days, id)
        .then((res) => {
          setDates(createDatesStates(res.data, closestDays, days));
          setIsLoading(false);
        })
        .catch(console.log);
    }
  };

  const createDatesStates = (available, closest, days) => {
    setAvailableSeats(
      _.uniqBy(
        [
          ...availableSeats,
          ...available.reduce(
            (accum, day) =>
              (day.availableSeats || []).length === 0
                ? [
                    ...accum,
                    moment(day.day)
                      .clone()
                      .toDate(),
                  ]
                : [...accum],
            [],
          ),
        ],
        (el) => JSON.stringify(el),
      ),
    );
    const checkAvailable = notAvailable(available, days);
    const checkType = chooseType(checkAvailable, currMonth);
    return chooseAvailable(checkType, closest);
  };

  const changeDayPart = (event, date) => {
    if (event.target) {
      setDayPart(event.target.value);
      setSelectedDate([
        { date: selectedDate[0].date, dayPart: event.target.value },
      ]);
      return;
    }
    setDayPart('day');
    setSelectedDate([{ date: moment(date).startOf('day'), dayPart: event }]);
  };

  const changeGuestsNumber = (event) => {
    setGuests(event.target.value);
  };

  const getSelectedMonthByDate = (date) => {
    if (date) {
      const thisMonth = moment().add(bookingAfterDays, 'days').startOf('month');
      const newMonthEnd = moment(date).endOf('month');
      const diff = newMonthEnd.diff(thisMonth, 'months');
      return diff;
    } else {
      return null;
    }
  };

  const changePickerMonth = (date) => {
    const newSelectedMonth = getSelectedMonthByDate(date);
    if (newSelectedMonth!==null) {
      setSelectedMonth(newSelectedMonth);
    }

    if (!date) {
      return setDatepickerDates([
        {
          day: moment()
            .clone()
            .startOf('month')
            .startOf('week'),
        },
        {
          day: moment()
            .clone()
            .endOf('month')
            .endOf('week'),
        },
      ]);
    }
    const start = moment(date)
      .clone()
      .startOf('month')
      .startOf('week');
    const end = moment(date)
      .clone()
      .endOf('month')
      .endOf('week');
    setDatepickerDates([
      {
        day: start,
      },
      {
        day: end,
      },
    ]);
  };

  const changeSelectedMonth = (type) => {
    setIsLoading(true);
    if (type === 'add') {
      setSelectedMonth(selectedMonth + 1);
    } else if (type === 'subtract') {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const changePage = (page) => {
    setError();
    setCurrPage(page);
  };

  const changeSelectedDay = (newDate, dayPart) => {
    if (
      selectedDate[0].date &&
      selectedDate[0].date.isSame(newDate) &&
      dayPart === selectedDate[0].dayPart
    ) {
      setSelectedDate([{}]);
      return;
    }
    if (
      selectedDate.length === 1 &&
      selectedDate[0].date &&
      selectedDate[0].date
        .clone()
        .add(1, 'day')
        .isSame(newDate) &&
      !(selectedDate[0].dayPart === 'am' || selectedDate[0].dayPart === 'pm') &&
      dayPart !== 'am' &&
      dayPart !== 'pm'
    ) {
      setSelectedDate([
        { ...selectedDate[0], dayPart: 'two' },
        { date: newDate, dayPart: 'two' },
      ]);
      setSchedule(fullSchedule);
      setDayPart('two');
      return;
    }
    if (
      selectedDate.length === 1 &&
      selectedDate[0].date &&
      selectedDate[0].date.clone().isSame(newDate) &&
      (dayPart === 'am' || dayPart === 'pm') &&
      (selectedDate[0].dayPart === 'am' || selectedDate[0].dayPart === 'pm')
    ) {
      setDayPart('day');
      setSchedule(fullSchedule);
      return;
    }
    if (
      selectedDate.length === 1 &&
      selectedDate[0].date &&
      selectedDate[0].date
        .clone()
        .subtract(1, 'day')
        .isSame(newDate) &&
      !(selectedDate[0].dayPart === 'am' || selectedDate[0].dayPart === 'pm') &&
      dayPart !== 'am' &&
      dayPart !== 'pm'
    ) {
      setSelectedDate([
        { date: newDate, dayPart: 'two' },
        { ...selectedDate[0], dayPart: 'two' },
      ]);
      setDayPart('two');
      return;
    }
    setSelectedDate([{ date: newDate, dayPart }]);
    setDayPart(dayPart);
  };

  const selectedDays = (days) => {
    return getAvailableSeats(days, id).then((res) => {
      let datepickerDates = [];
      if (days.length > 0) {
        for (
          let i = days[0].day.clone();
          i < days[1].day.clone().add(1, 'day');
          i.add(1, 'day')
        ) {
          datepickerDates = [...datepickerDates, { day: i.clone() }];
        }
      }
      setDatePickerDays(
        createDatesStates(res.data, closestDays, datepickerDates),
      );

      setAvailableSeats(
        _.uniqBy(
          [
            ...availableSeats,
            ...res.data.reduce(
              (accum, day) =>
                (day.availableSeats || []).length === 0
                  ? [...accum, moment(day.day).toDate()]
                  : accum,
              [],
            ),
          ],
          (el) => JSON.stringify(moment(el).startOf('day')),
        ),
      );
    });
  };

  //USE EFFECTS

  useEffect(() => {
    if (selectedRoomSlug) closeApp()
  }, [selectedRoomSlug]);

  useEffect(() => {
    if (selectedDate && selectedDate[0]) {
      if (selectedDate[0].dayPart === 'pm') {
        setSchedule(pmSchedule);
        setGuidedTourTime('first_before');
      } else if (selectedDate[0].dayPart === 'am') {
        setSchedule(amSchedule);
        setGuidedTourTime('first_after');
      } else if (
        selectedDate[0].dayPart === 'day' ||
        selectedDate[0].dayPart === 'two'
      ) {
        setSchedule(fullSchedule);
        setGuidedTourTime('first_before');
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    setDinnerPackages(generateDefaultPackages(addonsPackages, dayPart, guests));
    if (addonsPackages) {
      dayPartPackage(addonsPackages, dayPart);
      setAddonsPrices({
        full_day: {
          name: dayPartPackage(addonsPackages, dayPart).packages[0].name,
          price: dayPartPackage(addonsPackages, dayPart).packages[0].products[0]
            .price,
          price_excl_tax: dayPartPackage(addonsPackages, dayPart).packages[0]
            .products[0].price_excl_tax,
          id: dayPartPackage(addonsPackages, dayPart).packages[0].products[0]
            .id,
          description: dayPartPackage(addonsPackages, dayPart).packages[0]
            .products[0].description,
        },
        guided: {
          name: addonsPackages.find((el) => el.order === 2).packages[0].name,
          price: addonsPackages.find((el) => el.order === 2).packages[0]
            .products[0].price,
          price_excl_tax: addonsPackages.find((el) => el.order === 2)
            .packages[0].products[0].price_excl_tax,
          id: addonsPackages.find((el) => el.order === 2).packages[0]
            .products[0].id,
          description: addonsPackages.find((el) => el.order === 2).packages[0]
            .products[0].description,
        },
        dinner: {
          name: addonsPackages.find((el) => el.order === 3).name,
          id: addonsPackages.find((el) => el.order === 3).name,
          description:
            props.lang === 'en'
              ? 'Check to see options'
              : 'Kryssa i för att se alternativ',
        },
      });
    }
  }, [addonsPackages, dayPart]);

  useEffect(() => {
    setDinnerPackages(generateDefaultPackages(addonsPackages, dayPart, guests));
  }, [guests]);

  useEffect(() => {
    changeTotal(
      roomPrice,
      addons,
      addonsPrices,
      dinnerPackages,
      guests,
      dayPart,
      setTotal,
    );
  }, [addons, guests, dinnerPackages, roomPrice]);

  useEffect(() => {
    setAllDates(
      _.uniqBy([...allDates, ...datepickerDays], (el) =>
        JSON.stringify(el.day.clone().startOf('day')),
      ),
    );
  }, [datepickerDays]);

  useEffect(() => {
    if (dates[0] && dates[0].hasOwnProperty('unavailable')) {
      setAllDates(
        _.uniqBy([...allDates, ...dates], (el) =>
          JSON.stringify(el.day.clone().startOf('day')),
        ),
      );
    }
    setSelectedMonth(selectedMonth);
  }, [dates]);

  useEffect(() => {
    setFetching(true);
    if (selectedDate.length > 0 && selectedDate[0].date) {
      getDayInfo(selectedDate[0].date.format('YYYY-MM-DD'), id)
        .then((res) => {
          return checkDayInfo(
            res,
            selectedDate,
            setDates,
            dates,
            setAllDates,
            allDates,
            setSelectedDate,
            availableSeats,
            setAvailableSeats,
          );
        })
        .then((res) => {
          if (
            res.some(
              (el) => el.day.isSame(selectedDate[0].date) && el.unavailable,
            )
          ) {
            setSelectedDate([{}]);
          }
          setFetching(false);
        });
    }
    if (selectedDate.length > 1 && selectedDate[1].date) {
      getDayInfo(selectedDate[1].date.format('YYYY-MM-DD'), id)
        .then((res) => {
          return checkDayInfo(
            res,
            selectedDate,
            setDates,
            dates,
            setAllDates,
            allDates,
            setSelectedDate,
            availableSeats,
            setAvailableSeats,
          );
        })
        .then((res) => {
          if (
            res.some(
              (el) => el.day.isSame(selectedDate[0].date) && el.unavailable,
            )
          ) {
            setSelectedDate([{}]);
          }
          setFetching(false);
        });
    }
  }, [selectedDate]);

  useEffect(() => {
    checkAvailability(allDates, selectedDate, setDayParts, setDayPart, dayPart);
  }, [selectedDate, dates, allDates, days]);

  useEffect(() => {
    if (dayPart === 'two') {
      return setSelectedDate([
        { date: selectedDate[0].date ? selectedDate[0].date : null, dayPart },
        {
          date: selectedDate[0].date
            ? selectedDate[0].date.clone().add(1, 'day')
            : null,
          dayPart,
        },
      ]);
    }
    setSelectedDate([
      { date: selectedDate[0] ? selectedDate[0].date : null, dayPart },
    ]);
  }, [dayPart]);

  useEffect(() => {
    fetchDays();
  }, [days]);

  useEffect(() => {
    if (dates.length > 0) {
      setDatepickerLoading(true);
      selectedDays(datepickerDates).then(() => setDatepickerLoading(false));
    }
  }, [datepickerDates]);

  useEffect(() => {
    setDates(makeCalendar(selectedMonth, setCurrMonth));
    setDays(makeCalendar(selectedMonth, setCurrMonth));
  }, [selectedMonth]);

  //COMPONENT

  return (
    <Calendar
      {...props}
      title={selectedRoom.roomName}
      id={selectedRoom.roomId}
      maxGuests={selectedRoom.maxGuests}
      days={dates}
      changeMonth={changeSelectedMonth}
      currMonth={currMonth}
      roomNotAvailable={roomNotAvailable}
      selectedRoom={selectedRoom}
      setSelectedRoomSlug={setSelectedRoomSlug}
      selectDate={changeSelectedDay}
      selectedDate={selectedDate}
      datepickerStartDate={datepickerStartDate}
      availableSeats={availableSeats}
      changePickerMonth={changePickerMonth}
      isLoading={isLoading}
      datepickerLoading={datepickerLoading}
      setSelectedDate={setSelectedDate}
      setDayPart={changeDayPart}
      dayPart={dayPart}
      setGuestNumber={changeGuestsNumber}
      currPage={currPage}
      setCurrPage={changePage}
      guestsNumber={guests}
      datePickerLoading={setDatepickerLoading}
      availableDayParts={dayParts}
      book={createVenueBooking}
      userInfo={userInfo}
      addons={addons}
      changeAddons={changeAddons}
      error={error}
      changePage={changePage}
      saveDetails={saveDetails}
      setDetails={changeDetails}
      selectDinnerOption={selectDinnerOption}
      isFetching={fetching}
      packages={dinnerPackages}
      changeAllergies={changeAllergies}
      allergies={allergies}
      roomPrice={roomPrice}
      total={total}
      schedule={schedule}
      addonsPrices={addonsPrices}
      twoDaysPage={twoDaysPage}
      twoDaysSchedule={twoDaysSchedule}
      changeSchedule={changeSchedule}
      closeApp={closeApp}
      guidedTourTime={guidedTourTime}
      setTwoDaysPage={setTwoDaysPage}
      isBlocked={isBlocked}
      isPaying={isPaying}
      isPaid={isPaid}
      setIsPaying={setIsPaying}
    />
  );
};
export default CalendarContainer;
