import React from 'react';
import P from './_common/P';
import H3 from './_common/H3';
import styled from 'styled-components';

const TotalBlock = ({ appWidth, total, lang }) => (
  <TotalWrapper>
    {appWidth >= 740 && <LeftBlock />}
    <Total>
      {appWidth >= 740 && <EmptyBlock />}
      <TotalLeft>
        <Top>
          <H3>{total.withoutVAT.toFixed(2)} kr</H3>
        </Top>
        <Bottom>
          <H3>{total.withVAT} kr</H3>
        </Bottom>
      </TotalLeft>
      <TotalRight>
        <Top>
          <H3>{lang === 'en' ? 'Excluding VAT' : 'Exklusive moms'}</H3>
        </Top>
        <Bottom>
          <H3>{lang === 'en' ? 'Including VAT' : 'Inklusive moms'}</H3>
        </Bottom>
      </TotalRight>
    </Total>
  </TotalWrapper>
);

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -5px;
`;

const Total = styled.div`
  align-self: flex-end;
  flex: 3;
  display: flex;
  flex-direction: ${(props) => props.theme.width < 740 && 'row-reverse'};
  margin-top: ${(props) => props.theme.width >= 740 && '-45px'};
`;

const TotalLeft = styled.div`
  flex: 3;
  margin-right: ${(props) => props.theme.width >= 740 && '40px'};
  text-align: right;
  margin-left: ${(props) => props.theme.width >= 740 && '10px'};
`;

const TotalRight = styled.div`
  flex: ${(props) => (props.theme.width >= 740 ? '5' : '3')};
`;

const Top = styled.div`
  margin-bottom: ${(props) => props.theme.width >= 740 ? '25px' : '10px'};
  font-size: 15px;
`;

const Bottom = styled.div`
  margin-bottom: ${(props) => props.theme.width >= 740 ? '20px' : '10px'};
  font-size: 15px;
`;

const Bold = styled(P)`
  font-weight: bold;
`;

const LeftBlock = styled.div`
  flex: 3;
`;

const EmptyBlock = styled.div`
  flex: 1;
`;

export default TotalBlock;
