import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as LoaderLight } from '../../images/loader-light.svg';
import { ReactComponent as ArrowRight } from '../../images/arrow-right.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(LoaderLight)`
  animation: ${rotate} 0.9s linear infinite;
  margin-top: 3px;
  width: 32px;
  height: 32px;
`;


const Button = ({ className, title, page, func, submit, unavailable, slug, animating }) => {
  const primary = ['next', 'pay'].includes(slug)

  return (
    <Link
      className={className}
      title={title}
      primary={primary}
      type={submit ? 'submit' : 'button'}
      onClick={!func || unavailable ? null : () => func(page)}
      unavailable={unavailable}
      animating={animating}>
      {animating &&
      <Spinner/>
      }
      {!animating && title}
      {!animating && primary && <span> <ArrowRight /> </span>}
    </Link>
  );
};

export default Button;

const Link = styled.button`
  display: ${(props) => props.primary ? 'inline-flex' : 'inline-block'};
  cursor: ${(props) => (props.unavailable ? 'default' : 'pointer')};
  font-family: ${(props) => props.theme.secondaryFont} !important;
  letter-spacing: .75px;
  font-size: 20px !important;
  line-height: ${(props) => props.primary ? 15 : 23}px !important;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: ${(props) => props.primary ? (props.theme.width < 740 ? '10px' : '20px') : '0px'};
  color: ${(props) => props.unavailable ? 
          (props.primary ? props.theme.white : props.theme.inactiveGrey) : 
          (!props.primary ? props.theme.blue : props.theme.white)};
  background-color: ${(props) => props.unavailable ? 
          (props.primary ? props.theme.inactiveGrey : ('transparent')) : 
          (props.primary ? props.theme.blue : 'transparent')};

  span {
    padding-left: 10px;
    transition: transform .1s ease-out;
  }

  ${(props) => !props.primary && `
    margin-top: 2px;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: currentColor;
      transition: width .35s;
    }
  `}

  &:hover {
    ${(props) => props.primary
      ? `span {
           transform: ${props.unavailable ? 'inherited' : 'translateX(4px)'};
         }`

      : `&::after {
          width: 100%;
        }`
    }
  }
`;
