import React, { useState } from 'react';
import Container from '../components/Container';
import SectionContainer from '../components/SectionContainer';
import Select from '../components/_common/Select';
import styled from 'styled-components';
import guests from '../helpers/generateGuests';
import ButtonsRow from '../components/ButtonsRow';
import AbsoluteContainer from '../components/AbsoluteContainer';
import TotalBlock from '../components/Total';
import OptionRow from '../components/OptionRow';
import DinnerOption from '../components/DinnerOption';
import VenueRow from '../components/VenueRow';

const Venue = ({
  appWidth,
  closeApp,
  roomName,
  addonsPrices,
  roomPrice,
  total,
  allergies,
  changeAllergies,
  setCurrPage,
  selectedDate,
  selectedGuests,
  setGuests,
  dayPart,
  addons,
  changeAddons,
  selectDinnerOption,
  packages,
  lang,
  currPage,
  maxGuests,
}) => {
  const [error, setError] = useState(false);
  const createPeople = () => {
    const number = [];
    if (dayPart === 'two') {
      for (let i = 0; i <= selectedGuests; i++) {
        number.push({ name: i * 2, value: i * 2 });
      }
    } else {
      for (let i = 0; i <= selectedGuests; i++) {
        number.push({ name: i, value: i });
      }
    }
    return number;
  };

  return (
    <AbsoluteContainer>
      <Container
        title={lang === 'en' ? 'Venue & Add ons' : 'Lokal & Tillägg'}
        closeApp={closeApp}>
        <SectionContainer
          lang={lang}
          title={lang === 'en' ? 'Venue' : 'LOKAL'}
          edit={() => setCurrPage('calendar')}>
          <VenueRow
            roomName={roomName}
            dayPart={dayPart}
            selectedDate={selectedDate}
            roomPrice={roomPrice}
            appWidth={appWidth}
            currPage={currPage}
            lang={lang}
            noGuests
          />
          <SelectWrapper>
            <Select
              changeSelect={setGuests}
              value={selectedGuests}
              name="guests"
              options={guests(maxGuests, lang)}
            />
          </SelectWrapper>
        </SectionContainer>
        <SectionContainer
          lang={lang}
          title={lang === 'en' ? 'add ons' : 'TILLÄGG'}>
          <AddonsWrapper>
            <OptionRow
              appWidth={appWidth}
              change={changeAddons}
              checkBoxName="full_day"
              value={addons.full_day}
              guests={dayPart === 'two' ? selectedGuests * 2 : selectedGuests}
              name={addonsPrices.full_day.name}
              caption={addonsPrices.full_day.description}
              price={addonsPrices.full_day.price_excl_tax}
              total={
                dayPart === 'two'
                  ? selectedGuests * addonsPrices.full_day.price_excl_tax * 2
                  : selectedGuests * addonsPrices.full_day.price_excl_tax
              }
            />
            <OptionRow
              appWidth={appWidth}
              change={changeAddons}
              checkBoxName="guided"
              value={addons.guided}
              name={addonsPrices.guided.name}
              caption={addonsPrices.guided.description}
              price={addonsPrices.guided.price_excl_tax}
              total={addonsPrices.guided.price_excl_tax}
            />
            <OptionRow
              hide
              appWidth={appWidth}
              change={changeAddons}
              checkBoxName="dinner"
              value={addons.dinner}
              name={addonsPrices.dinner.name}
              caption={
                addons.dinner
                  ? lang === 'en'
                    ? 'Please select packages below'
                    : 'Vänligen välj paket nedan'
                  : addonsPrices.dinner.description
              }
            />
            {addons.dinner && (
              <>
                {packages.map((el) => (
                  <DinnerOption
                    key={el.slug}
                    appWidth={appWidth}
                    slug={el.slug}
                    title={el.name}
                    options={el.packages}
                    values={createPeople()}
                    selectDinnerOption={selectDinnerOption}
                    value={el}
                    lang={lang}
                  />
                ))}
              </>
            )}
            <OptionRow
              appWidth={appWidth}
              allergies={allergies}
              changeAllergies={changeAllergies}
              change={changeAddons}
              checkBoxName="allergies"
              value={addons.allergies}
              name={lang === 'en' ? 'Allergies' : 'Allergier'}
              allergiesError={error}
              lang={lang}
            />
          </AddonsWrapper>
        </SectionContainer>
        <SectionContainer title={lang === 'en' ? 'total price' : 'TOTALT PRIS'}>
          <TotalBlock appWidth={appWidth} total={total} lang={lang} />
        </SectionContainer>
        <SectionContainer title="">
          <ButtonsRow
            back="calendar"
            next="schedule"
            funcBack={closeApp}
            funcNext={
              allergies.length > 0
                ? allergies.match(/^[0-9a-zA-ZÅåÄäÖö\s]*$/) &&
                  allergies.length < 60
                  ? () => {
                      setCurrPage('schedule');
                      setError(false);
                    }
                  : () => setError(true)
                : () => {
                    setCurrPage('schedule');
                    setError(false);
                  }
            }
            lang={lang}
          />
        </SectionContainer>
      </Container>
    </AbsoluteContainer>
  );
};

export default Venue;

const SelectWrapper = styled.div`
  width: 100px;
`;

const AddonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
