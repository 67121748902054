export const generateHours = (start, end, startHalf, endHalf) => {
  let hours = [];
  if ((!startHalf && !endHalf) || (startHalf === '00' && endHalf === '00'))
    for (let i = start; i <= end; i++) {
      if (i === end) {
        hours.push({
          value: `${end}:00`,
          name: end < 10 ? `0${end}:00` : `${end}:00`,
        });
      } else {
        hours.push(
          { value: `${i}:00`, name: i < 10 ? `0${i}:00` : `${i}:00` },
          { value: `${i}:30`, name: i < 10 ? `0${i}:30` : `${i}:30` },
        );
      }
    }
  else if (start === end && startHalf !== endHalf) {
    hours.push(
      {
        value: `${start}:00`,
        name: start < 10 ? `0${start}:00` : `${start}:00`,
      },
      {
        value: `${start}:30`,
        name: start < 10 ? `0${start}:30` : `${start}:30`,
      },
    );
  } else if (endHalf === '30' && startHalf === '30') {
    for (let i = start; i <= end; i++) {
      if (i === start) {
        hours.push({
          value: `${start}:30`,
          name: start < 10 ? `0${start}:30` : `${start}:30`,
        });
      } else {
        hours.push(
          { value: `${i}:00`, name: i < 10 ? `0${i}:00` : `${i}:00` },
          { value: `${i}:30`, name: i < 10 ? `0${i}:30` : `${i}:30` },
        );
      }
    }
  } else if (startHalf === '30' && endHalf === '00') {
    for (let i = start; i <= end; i++) {
      if (i === start) {
        hours.push({
          value: `${start}:30`,
          name: start < 10 ? `0${start}:30` : `${start}:30`,
        });
      } else if (i === end) {
        hours.push({
          value: `${end}:00`,
          name: end < 10 ? `0${end}:00` : `${end}:00`,
        });
      } else {
        hours.push(
          { value: `${i}:00`, name: i < 10 ? `0${i}:00` : `${i}:00` },
          { value: `${i}:30`, name: i < 10 ? `0${i}:30` : `${i}:30` },
        );
      }
    }
  } else if (endHalf === '30') {
    for (let i = start; i <= end; i++) {
      hours.push(
        { value: `${i}:00`, name: i < 10 ? `0${i}:00` : `${i}:00` },
        { value: `${i}:30`, name: i < 10 ? `0${i}:30` : `${i}:30` },
      );
    }
  }
  return hours;
};
