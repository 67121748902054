import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => {
    return props.scroll ? '20px' : 'auto';
  }};
  overflow: ${(props) => {
    return props.scroll ? 'hidden' : 'visible';
  }};
  margin-bottom: 45px;
`;
