import moment from 'moment';
import { bookingAfterDays } from './chooseType';

export const checkAvailability = (
  dates,
  selectedDate,
  setDayParts,
  setDayPart,
  dayPart,
) => {
  const dayParts = [];

  if (selectedDate[0] && !selectedDate[0].date) {
    setDayParts(['two', 'day', 'am', 'pm']);
    return;
  }
  if (
    dates.some(
      (day, index, days) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        day.type &&
        !day.dayPart &&
        days.some(
          (date) =>
            date.day.isSame(day.day.clone().add(1, 'day')) &&
            date.type &&
            !date.unavailable &&
            !date.dayPart &&
            day.day.weekday() !== 5,
        ),
    )
  ) {
    dayParts.push('two');
  }
  if (
    dates.some(
      (day) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        !day.dayPart &&
        day.type,
    )
  ) {
    dayParts.push('day');
  }
  if (
    dates.some(
      (day) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        day.day <
          moment()
            .clone()
            .add(bookingAfterDays + 7 - 1, 'day') &&
        day.dayPart !== 'am' &&
        day.type,
    )
  ) {
    dayParts.push('am');
  }
  if (
    dates.some(
      (day) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        day.day <
          moment()
            .clone()
            .add(bookingAfterDays + 7 - 1, 'day') &&
        day.dayPart !== 'pm' &&
        day.type,
    )
  ) {
    dayParts.push('pm');
  }
  if (
    dates.some(
      (day) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        day.dayPart === 'pm',
    )
  ) {
    setDayPart('am');
  }
  if (
    dates.some(
      (day) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        day.dayPart === 'am',
    )
  ) {
    setDayPart('pm');
  }
  if (
    dates.some(
      (day) =>
        day.day.isSame(selectedDate[0] && selectedDate[0].date) &&
        day.type &&
        day.type !== 'closest',
    )
  ) {
    if (dayPart === 'two') {
      setDayPart('two');
      return;
    }
    setDayPart('day');
  }
  setDayParts([...dayParts]);
};
