import React from 'react';
import './App.css';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { theme } from './helpers/theme';
import { ThemeProvider } from 'styled-components';
import ScheduleContainer from './containers/ScheduleContainer';

function App(props) {
  return (
    <Wrapper className="App">
      <AutoSizer disableHeight={true} style={{ width: '100%' }}>
        {({ width }) => (
          <ThemeProvider theme={{ ...theme, width }}>
            <ScheduleContainer
              appWidth={width}
              lang={props.language || 'en'}
              roomSelector={props.roomSelector === 'yes'}
              {...props}
            />
          </ThemeProvider>
        )}
      </AutoSizer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 15px;
`;

export default App;
