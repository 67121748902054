import React from 'react';
import Container from '../components/Container';
import SectionContainer from '../components/SectionContainer';
import ButtonsRow from '../components/ButtonsRow';
import AbsoluteContainer from '../components/AbsoluteContainer';
import TotalBlock from '../components/Total';
import ScheduleBlock from '../components/ScheduleBlock';
import VenueRow from '../components/VenueRow';
import AddonsBlock from '../components/AddonsBlock';

const Summary = ({
  appWidth,
  closeApp,
  roomName,
  schedule,
  twoDaysSchedule,
  setCurrPage,
  selectedDate,
  dayPart,
  addons,
  packages,
  allergies,
  guests,
  total,
  roomPrice,
  lang,
  addonsPrices,
  guidedTourTime,
  setTwoDaysPage,
}) => {
  return (
    <AbsoluteContainer>
      <Container
        title={lang === 'en' ? 'Summary' : 'Summering'}
        closeApp={closeApp}>
        <SectionContainer
          title={lang === 'en' ? 'Venue' : 'LOKAL'}
          lang={lang}
          edit={() => {
            setTwoDaysPage(1);
            setCurrPage('calendar');
          }}>
          <VenueRow
            appWidth={appWidth}
            roomName={roomName}
            dayPart={dayPart}
            selectedDate={selectedDate}
            roomPrice={roomPrice}
            guests={guests}
            lang={lang}
          />
        </SectionContainer>
        <SectionContainer
          lang={lang}
          title={lang === 'en' ? 'Add ons' : 'TILLÄGG'}
          edit={() => {
            setTwoDaysPage(1);
            setCurrPage('venue');
          }}>
          <AddonsBlock
            appWidth={appWidth}
            addons={addons}
            allergies={allergies}
            guests={guests}
            packages={packages}
            selectedDate={selectedDate}
            lang={lang}
            addonsPrices={addonsPrices}
          />
        </SectionContainer>
        <SectionContainer title={lang === 'en' ? 'total price' : 'TOTALT PRIS'}>
          <TotalBlock appWidth={appWidth} total={total} lang={lang} />
        </SectionContainer>
        <SectionContainer
          title={lang === 'en' ? 'schedule' : 'Schema'}
          lang={lang}
          edit={() => {
            twoDaysSchedule('back');
            setCurrPage('schedule');
          }}>
          {selectedDate.map((day, index) => (
            <ScheduleBlock
              key={index}
              day={day}
              selectedDay={index}
              schedule={schedule}
              addons={addons}
              selectedDate={selectedDate}
              lang={lang}
              guidedTourTime={guidedTourTime}
              addonsPrices={addonsPrices}
              appWidth={appWidth}
            />
          ))}
        </SectionContainer>
        <SectionContainer title="">
          <ButtonsRow
            back="schedule"
            next="payment"
            funcBack={setCurrPage}
            funcNext={setCurrPage}
            lang={lang}
          />
        </SectionContainer>
      </Container>
    </AbsoluteContainer>
  );
};

export default Summary;
