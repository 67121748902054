import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Switch, Route, useParams, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Rooms from './Rooms';
import { getRoom, getRooms } from './helpers/rooms';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

const roots = document.getElementsByClassName('fotogr-book-calendar-book');
const rooms = getRooms();

for (let i = 0; i < roots.length; i++) {
  let root = roots[i];

  ReactDOM.render(
    <BrowserRouter>
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            {
              cssSrc:
                'https://use.typekit.net/kyv2dbz.css'
            }
          ]
        }}>
        <App {...root.dataset} />
      </Elements>
    </BrowserRouter>,
    root
  );
}


function AppWrapper(props) {
  if ('room' in props) {
    const {room: slug} = props;
    const room = getRoom(slug);

    if (room) {
      delete props['room'];
      return App({...room, basePath: `/${room.slug}`, rooms, ...props});
    }
  } else {
    return App(rooms, ...props);
  }
}

function SelectedAppFromParams(props) {
  const params = useParams();
  if ('room' in params) {
    const {room: slug} = params;
    const room = (slug === 'room-selector') ? {} : getRoom(slug);

    if (room) {
      return App({...room, rooms, basePath: `/${room.slug}`, roomSelector: slug === 'room-selector'});
    }
  }
  return <Redirect to={"/"}/>
}

let currentAppContainer = null;
let appRendering = false;

function renderApp() {
  if (appRendering) {
    return;
  }
  appRendering = true;
  const appRoot = document.querySelector('.fg-room-booking-app');
  if (appRoot != null && appRoot.children.length==0) {

    if (currentAppContainer) {
      unmountComponentAtNode(currentAppContainer);
    }

    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          <Route path="/:room">
            <Elements
              stripe={stripePromise}
              options={{
                fonts: [
                  {
                    cssSrc:
                      'https://use.typekit.net/kyv2dbz.css'
                  }
                ]
              }}>
              {appRoot.dataset?.room ? <AppWrapper {...appRoot.dataset} /> : <SelectedAppFromParams/>}
            </Elements>
          </Route>
          <Route path={['/']}>
            <Rooms rooms={getRooms()}/>
          </Route>
        </Switch>

      </BrowserRouter>,
      appRoot
    );
    currentAppContainer = appRoot;
  }
  appRendering = false;
}

const repeat = () => {
  renderApp();
  setTimeout(() => {
    repeat();
  }, 1000);
}

repeat();

serviceWorker.unregister();
