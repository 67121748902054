import React from 'react';
import styled from 'styled-components';
import H2 from './_common/H2';

const SectionContainer = ({ children, title, edit, schedule, lang }) => (
  <SectionWrapper schedule={schedule}>
    {title && (
      <Title title={title.split(' ')[0]} schedule={schedule}>
        {title}
      </Title>
    )}
    {children}
    {edit && (
      <Edit onClick={() => edit()}>{lang === 'en' ? `Edit` : `Ändra`}</Edit>
    )}
  </SectionWrapper>
);

export default SectionContainer;

const SectionWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.theme.width < 740 ? '47px' : (props.schedule ? '44px' : '28px'))};
  border-top: 2px solid ${(props) => props.theme.lineBlack};
  text-align: left;
  padding-top: 24px;
`;

const Title = styled(H2)`
  margin-top: ${(props) => (props.schedule ? '2px' : '0')} !important;
  margin-bottom: ${(props) => (props.schedule ? '10px' : '24px')} !important;
  font-size: ${(props) => (props.schedule ? (props.theme.width < 740 ? '15px' : '20px') : '25px')} !important;
  font-family: ${(props) => props.schedule ? props.theme.mainFont : props.theme.secondaryFont} !important;
  font-weight: normal !important;
`;

const Edit = styled.a`
  font-family: ${(props) => props.theme.secondaryFont} !important;
  font-size: 20px !important;
  line-height: 20px;
  color: ${(props) => props.theme.blue};
  position: absolute;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  right: 0;
  top: 28px;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width .35s;
  }

  &:hover::after {
    width: 100%;
  }
`;
