export const generateDefaultPackages = (addonsPackages, dayPart, guests) => {
  if (addonsPackages) {
    const dinner = addonsPackages.find((el) => el.order === 3).packages;
    return dinner.map((el, key) => {
      return {
        value: 1,
        slug: el.name
          .split(' ')
          .join('')
          .toLowerCase(),
        number: key === 0 ? (dayPart === 'two' ? +guests * 2 : +guests) : 0,
        name: el.name,
        packages: [
          ...el.products.map((el, key) => {
            return {
              name: el.name,
              price_excl_tax: el.price_excl_tax,
              price: el.price,
              id: el.id,
              value: key + 1,
            };
          }),
        ],
      };
    });
  }
};
