import React, { useState } from 'react';
import './App.css';
import AutoSizer from 'react-virtualized-auto-sizer';
import { theme } from './helpers/theme';
import { useHistory } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import * as PropTypes from 'prop-types';
import Room from './components/Room';
import H1 from './components/_common/H1';
import { Container } from './components/_common/Container';
import P from './components/_common/P';
import Button from './components/_common/Button';

Room.propTypes = { room: PropTypes.any };

function Rooms({ rooms }) {
  const history = useHistory()

  return (
    <div className="Rooms">
      <AutoSizer disableHeight={true} style={{ width: '100%' }}>
        {({ width }) => (
          <ThemeProvider theme={{ ...theme, width }}>
            <Container appWidth={width}>
              <H1>Rooms</H1>
              <P>Pick a room</P>
              <RoomsWrapper>
                {rooms.map(room => {
                  return <Room key={room.slug} room={room}/>;
                })}
              </RoomsWrapper>

              <StyledButton title='With Room Selector'
                            func={() => history.push('room-selector')}
                            slug='next'
              />
            </Container>

          </ThemeProvider>
        )}
      </AutoSizer>
    </div>
  );
}

const RoomsWrapper = styled.div`
  margin: 30px 0;
`;

const StyledButton = styled(Button)`
  align-self: flex-start;
`;

export default Rooms;

