import React, { useState } from 'react';
import CheckboxInput from '../CheckboxInput';
import styled from 'styled-components';

const Checkbox = ({
  title,
  value,
  change,
  name,
  linkText,
  link,
  unavailable,
  className,
}) => {
  return (
    <Wrapper className={className}>
      {unavailable && <GreyOutBlock />}
      <StyledLabel>
        <CheckboxInput
          checked={value}
          onChange={(e) => change(e)}
          name={name}
        />
        {title && (
          <StyledText>
            {title}
            <StyledA href={link} target="_blank" rel="noopener noreferrer">
              {linkText}
            </StyledA>
          </StyledText>
        )}
      </StyledLabel>
    </Wrapper>
  );
};

export default Checkbox;

const Wrapper = styled.div`
  position: relative;
`;

const GreyOutBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  // background-color: rgba(255, 255, 255, 0.7);
  z-index: 5;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  line-height: 20px;
  display: flex;
`;

const StyledText = styled.span`
  font-family: 'minion-pro', sans-serif !important;
  font-size: 15px;
  color: ${(props) => props.theme.black};
  user-select: none;
  margin-left: 15px;
  max-width: 380px;
  line-height: 15px;
`;

const StyledA = styled.a`
  font-family: 'minion-pro', sans-serif !important;
  font-size: 15px;
  color: ${(props) => props.theme.black};
  user-select: none;
  text-decoration: underline;
`;
