import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import Input from './_common/Input';
import Button from './_common/Button';
import { ReactComponent as Loader } from '../images/loader.svg';
import P from './_common/P';

const CreditCardPayment = ({
  total,
  lang,
  register,
  errors,
  isBlocked,
  isPaid,
  isPaying,
  appWidth
}) => {
  const [ready, setReady] = useState(false);

  const createOptions = (placeholder, appWidth) => {
    return {
      placeholder,
      style: {
        base: {
          fontSize: appWidth < 740 ? '15px' : '20px',
          lineHeight: appWidth < 740 ? '15px' : '20px',
          color: '#212529',
          fontFamily: '"minion-pro", sans-serif',
          letterSpacing: 'normal',
          borderRadius: 1,
          '::placeholder': {
            color: '#767676',
            opacity: 1,
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  return (
    <>
      {!isPaid ? (
        <Wrapper>
          <InputWrapper>
            {isPaying && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
            <Input
              title={lang === 'en' ? 'Name on card' : 'Namn på kortet'}
              name="card_name"
              ref={register({
                required: 'Required',
                minLength: {
                  value: 2,
                  message: 'Name is too short',
                },
                maxLength: {
                  value: 40,
                  message: 'Name is too long',
                },
                pattern: {
                  value: /^[a-zA-ZÅåÄäÖö\s]*$/,
                  message: 'Invalid name format',
                },
              })}
              error={errors.card_name}
            />
          </InputWrapper>
          <InputWrapper>
            <StyledCardNumberElement
              options={createOptions(
                lang === 'en' ? 'Card number' : 'Kortnummer',
                appWidth
              )}
            />
          </InputWrapper>
          <DateWrapper>
            <SmallInput>
              <StyledCardExpiryElement
                options={createOptions(lang === 'en' ? 'Expiration' : 'Utgår', appWidth)}
              />
            </SmallInput>
            <SmallInput>
              <Input
                title={lang === 'en' ? 'Post code' : 'Postnr'}
                name="postal_code"
                ref={register({
                  required: 'Required',
                  minLength: {
                    value: 3,
                    message: 'Post code number is too short',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Post code number is too long',
                  },
                  pattern: {
                    value: /^[0-9a-zA-ZÅåÄäÖö\s]*$/,
                    message: 'Invalid post code',
                  },
                })}
                error={errors.postal_code}
              />
            </SmallInput>
            <SmallInput CVC>
              <StyledCvc options={createOptions(undefined, appWidth)} />
            </SmallInput>
          </DateWrapper>
          <ButtonWrapper>
            <Button
              title={`Pay ${total.withVAT} kr`}
              slug="pay"
              submit={!isBlocked}
              unavailable={isBlocked}
            />
          </ButtonWrapper>
        </Wrapper>
      ) : (
        <Success>
          <Circle>
            <CheckMark />
          </Circle>
          <StyledP>
            {lang === 'en' ? `Payment successful!` : `Betalning genomförd!`}
          </StyledP>
          <StyledP>
            {lang === 'en'
              ? `Press “next” to continue`
              : `Klicka “nästa” för att fortsätta!`}
          </StyledP>
        </Success>
      )}
    </>
  );
};

export default CreditCardPayment;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${(props) => props.theme.width < 740 && '50px'};
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SmallInput = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-right: 10%;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 23px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  font-family: 'minion-pro', sans-serif !important;
  font-size: ${(props) => props.theme.width < 740 ? '15px' : '20px'} !important;
  color: ${(props) => props.theme.black} !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  border-radius: 0 !important;
  border-bottom: 1px solid ${(props) => props.theme.black} !important;
  line-height: 15px;
  padding-top: 17px !important;
  height: 50px;
  box-sizing: border-box;
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  font-family: 'minion-pro', sans-serif !important;
  font-size: ${(props) => props.theme.width < 740 ? '15px' : '20px'} !important;
  color: ${(props) => props.theme.black} !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  border-radius: 0 !important;
  border-bottom: 1px solid ${(props) => props.theme.black} !important;
  line-height: 15px;
  padding-top: 18px !important;
  height: 50px;
  box-sizing: border-box;
`;

const StyledCvc = styled(CardCvcElement)`
  font-family: 'minion-pro', sans-serif !important;
  font-size: ${(props) => props.theme.width < 740 ? '15px' : '20px'} !important;
  color: ${(props) => props.theme.black} !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  border-radius: 0 !important;
  border-bottom: 1px solid ${(props) => props.theme.black} !important;
  line-height: 15px;
  padding-top: 18px !important;
  height: 50px;
  box-sizing: border-box;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 255, 255, 0.7);
  z-index: 5;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Loader)`
  animation: ${rotate} 0.9s linear infinite;
  margin-top: 0px;
`;

const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props) => props.theme.width < 740 && '50px'};
`;

const Circle = styled.div`
  border: 6px solid #ace9d2;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
`;

const CheckMark = styled.div`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-top: -5px;
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 5px;
    background-color: #25b47e;
    border-radius: 3px;
    content: '';
    transform: translateX(20px) rotate(-45deg);
    transform-origin: left bottom;
  }
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: #25b47e;
    border-radius: 3px;
    content: '';
    transform: translateX(16px) rotate(-45deg);
    transform-origin: left bottom;
  }
`;

const StyledP = styled(P)`
  margin-bottom: 20px !important;
`;
