export const scheduleOpt = (selectedDate, lang) => {
  if (selectedDate[0].dayPart === 'am') {
    return lang === 'en'
      ? [
          {
            title: 'Arrival',
            slug: 'arrival',
            text: 'Arrival through the museum shop',
          },
          { title: 'Fika', slug: 'fika', text: 'Serving location is confirmed at the venue' },
          { title: 'Lunch', slug: 'lunch', text: 'Served in the restaurant' },
          { title: 'Ends', slug: 'ends', text: '' },
        ]
      : [
          {
            title: 'Ankomst',
            slug: 'arrival',
            text: 'Ankomst genom butiken',
          },
          { title: 'Fika', slug: 'fika', text: 'Plats för servering bekräftas på plats' },
          { title: 'Lunch', slug: 'lunch', text: 'Serveras i restaurangen' },
          { title: 'Slut', slug: 'ends', text: '' },
        ];
  } else if (selectedDate[0].dayPart === 'pm') {
    return lang === 'en'
      ? [
          {
            title: 'Arrival',
            slug: 'arrival',
            text: 'Arrival through the museum shop',
          },
          { title: 'Lunch', slug: 'lunch', text: 'Served in the restaurant' },
          { title: 'Fika', slug: 'fika2', text: 'Serving location is confirmed at the venue' },
          { title: 'Ends', slug: 'ends', text: '' },
        ]
      : [
          {
            title: 'Ankomst',
            slug: 'arrival',
            text: 'Ankomst genom butiken',
          },
          { title: 'Lunch', slug: 'lunch', text: 'Serveras i restaurangen' },
          { title: 'Fika', slug: 'fika2', text: 'Plats för servering bekräftas på plats' },
          { title: 'Slut', slug: 'ends', text: '' },
        ];
  } else {
    return lang === 'en' ? scheduleOptions : scheduleOptionsSV;
  }
};

export const scheduleOptions = [
  {
    title: 'Arrival',
    slug: 'arrival',
    text: 'Arrival through the museum shop',
  },
  { title: 'Fika', slug: 'fika', text: 'Serving location is confirmed at the venue' },
  { title: 'Lunch', slug: 'lunch', text: 'Served in the restaurant' },
  { title: 'Fika', slug: 'fika2', text: 'Serving location is confirmed at the venue' },
  { title: 'Ends', slug: 'ends', text: '' },
];

export const scheduleOptionsSV = [
  {
    title: 'Ankomst',
    slug: 'arrival',
    text: 'Ankomst genom butiken',
  },
  { title: 'Fika', slug: 'fika', text: 'Plats för servering bekräftas på plats' },
  { title: 'Lunch', slug: 'lunch', text: 'Serveras i restaurangen' },
  { title: 'Fika', slug: 'fika2', text: 'Plats för servering bekräftas på plats' },
  { title: 'Slut', slug: 'ends', text: '' },
];
