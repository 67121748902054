import styled from 'styled-components';

const P = styled.p`
  font-family: 'minion-pro', sans-serif !important;
  font-size: 15px !important;
  line-height: 15px !important;
  color: ${(props) => props.theme.black};
  margin: 0 !important;
`;

export default P;
